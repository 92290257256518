import initialState from "../initialState";
import { TOGGLE_SIDEBAR } from "../actions/sidebar";
import { createReducer } from "@reduxjs/toolkit";

const sidebar = createReducer(initialState.sidebar, {
  [TOGGLE_SIDEBAR]: (state) => {
    state.collapsed = !state.collapsed;
  },
});

export default sidebar;
