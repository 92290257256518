import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import placeholderPic from "../../../assets/no_data_x.gif";
import GenericHeader from "../../../components/GenericHeader/GenericHeader";
import GenericTable from "../../../components/GenericTable/GenericTable";
import blocksTable from "configs/blocksTable";
import BlockService from "services/Entities/BlockService";
import useUrlState from "@ahooksjs/use-url-state";
import { useApiHook } from "providers/ApiProvider";
import { useActiveUserPermissions, usePermissionChecker } from "hooks/useRoles";
import { showErrorAlert, showSuccessAlert } from "store/actions/alerts";

import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BlockDialog from "components/Dialogs/BlockDialog/BlockDialog";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";

const BlocksTableView = () => {
  const currentUserPermissions = useActiveUserPermissions();

  const [selectedRows, setSelectedRows] = useState([]);

  const history = useHistory();
  const dispatch = useDispatch();
  const permissionChecker = usePermissionChecker();

  const [searchQuery, setSearchQuery] = useState(
    localStorage.getItem("searchQuery") || ""
  );

  const initConfig: {
    title: string;
    body: JSX.Element | null;
    visible: boolean;
  } = {
    title: "",
    body: null,
    visible: false,
  };

  const [config, setConfig] = useState<any>(initConfig);

  const handleViewClick = (row) => {
    setConfig({
      title: 'Properties in Block',
      body: propertiesTable(row),
      visible: true,
    })
  }


  const [queryParams, setQueryParams] = useUrlState({
    q: searchQuery ?? "",
    page: 0,
    pageSize: 10,
    // sortBy: "releaseDate",
    // sortDirection: "desc",
  });

  const query = {
    query_all: queryParams.q,
    page: Number(queryParams.page),
    pageSize: queryParams.pageSize,
  };

  const blocksClient = new BlockService();
  const blocksData = useApiHook("Blocks", "getAllResources", { query });

  const handleSearch = (value: string) => {
    localStorage.setItem("searchQuery", value);
    setSearchQuery(value);

    if (queryParams.q !== value) {
      setQueryParams({ q: value, page: 0 });
    } else {
      setQueryParams({ q: value });
    }
  };

  const deleteBlocks = () => {
    if (!permissionChecker("delete-block", true)) return;

    blocksClient.deleteManyResource({ data: selectedRows }).then((res) => {
      if (res?.data?.success) {
        dispatch(showSuccessAlert("Block Deleted"));
        blocksData.refresh();
      } else {
        dispatch(showErrorAlert(res?.data?.message));
      }
    });
  };

  const navigateToAddPage = () => {
    history.push(`blocks/add`);
  };

  const rowTriggerAction = (row: any) => {
    history.push(`blocks/${row?._id}`);
  };

  const handlePageChange = (e: any, value: any) => {
    setQueryParams({ page: value });
  };

  const handleRowsChange = (e: { target: { value: string } }) => {
    setQueryParams({ page: 0, pageSize: parseInt(e.target.value) });
  };

  const handleRowSelectionChange = (data: any) => {
    setSelectedRows(data);
  };

  const propertiesTable = (row) => {
    return (
      <TableContainer>
        <Table>
          <TableHead sx={{ background: "#F6F7FC" }}>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Type of Property</TableCell>
              <TableCell>Type of Building</TableCell>
              <TableCell>Availability</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Area</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {row.properties.map((property) => (
              <TableRow key={property._id}>
                <TableCell>{property.title}</TableCell>
                <TableCell>{property.property_type}</TableCell>
                <TableCell>{property.building_type}</TableCell>
                <TableCell>{property.availability_status}</TableCell>
                <TableCell>{property.price}</TableCell>
                <TableCell>{property.area}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  return (
    <div className="container-fluid p-0">
      <GenericHeader
        selectedRows={selectedRows}
        onSearch={handleSearch}
        addResourceHandler={navigateToAddPage}
        deleteResourceHandler={deleteBlocks}
        model="block"
      />
      <div className="p-4">
        <h2 className="mb-4">Blocks</h2>
        <Typography variant="body2" color="text.secondary" mb={4}>
          <Box sx={{ mb: 0.7 }}>A way to group properties together</Box>
          {currentUserPermissions?.includes("edit-block")
            ? "View/Edit"
            : "View"}{" "}
          the properties assigned to a block by clicking on the{" "}
          <Button
            size="small"
            disableElevation
            color="primary"
            variant="contained"
            endIcon={
              currentUserPermissions?.includes("edit-block") ? (
                <EditIcon />
              ) : (
                <VisibilityIcon />
              )
            }
            sx={{
              transform: "scale(0.8)",
              pointerEvents: "none",
              ml: -0.5,
            }}
          >
            {currentUserPermissions?.includes("edit-block") ? "Edit" : "View"}
          </Button>
          <br /> To add a new Block click the
          <IconButton
            size="small"
            sx={{
              transform: "scale(0.8)",
              mx: 0.2,
              backgroundColor: "background.paper",
              borderRadius: "2px",
              boxShadow: "0px 6px 13px 0px #00000005",
              pointerEvents: "none",
            }}
          >
            <AddCircleIcon color="primary" />
          </IconButton>{" "}
          in the top right corner
        </Typography>
        <GenericTable
          list={blocksData?.data?.data ? blocksData.data.data : []}
          totalCount={blocksData?.data?.total}
          onSelectedChange={handleRowSelectionChange}
          selectedRows={selectedRows}
          page={Number(queryParams.page)}
          loading={blocksData.loading}
          rowsPerPage={Number(queryParams.pageSize)}
          onRowsChange={handleRowsChange}
          onPageChange={handlePageChange}
          image={placeholderPic}
          tableConfig={blocksTable}
          model="block"
          passRow={rowTriggerAction}
          showViewButton={true}
          onViewClick={(row) => handleViewClick(row)}
        />
      </div>
      <BlockDialog config={config} setConfig={setConfig} />
    </div>
  );
};

export default BlocksTableView;
