import { createRoot } from "react-dom/client";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { persistor, store } from "./store/index";
import { ApiProvider } from "./providers/ApiProvider";
import { ApiMiddleware } from "services/apiMiddlewares";
import { LocationFieldProvider } from "providers/LocationFieldProvider";

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <>
    {/* <!-- Google Fonts --> */}
    <link
      href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      rel="stylesheet"
    />
    {/* <!-- MDB --> */}
    <link
      href="https://cdnjs.cloudflare.com/ajax/libs/mdb-ui-kit/3.6.0/mdb.min.css"
      rel="stylesheet"
    />
    <>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ApiProvider middleware={ApiMiddleware}>
            {/* <LocationFieldProvider
              apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY as string}
            > */}
            <App />
            {/* </LocationFieldProvider> */}
          </ApiProvider>
        </PersistGate>
      </Provider>
    </>
    {/* <!-- MDB --> */}
    <script
      type="text/javascript"
      src="https://cdnjs.cloudflare.com/ajax/libs/mdb-ui-kit/3.6.0/mdb.min.js"
    ></script>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
