import { TableConfig } from "interfaces/tableConfig";

const mailingList: TableConfig[] = [
  {
    heading: "ID",
    attribute: "_id",
    type: "string",
    sortable: false,
    selected: false,
  },
  {
    heading: "Email",
    attribute: "email",
    type: "string",
    sortable: true,
    selected: true,
  },
  {
    heading: "Subscribed",
    attribute: "subscribed",
    type: "boolean",
    sortable: true,
    selected: true,
  },
];

export default mailingList;
