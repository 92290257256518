import { useState } from "react";
import placeholderPic from "../../../assets/no_data_x.gif";
import GenericHeader from "../../../components/GenericHeader/GenericHeader";
import GenericTable from "../../../components/GenericTable/GenericTable";
import useUrlState from "@ahooksjs/use-url-state";
import { useApiHook } from "providers/ApiProvider";
import mailingList from "configs/mailingList";
import { usePermissionChecker } from "hooks/useRoles";
import MailingListService from "services/Entities/MailingListService";
import { useDispatch } from "react-redux";
import { showErrorAlert, showSuccessAlert } from "store/actions/alerts";

const MailingListTableView = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const dispatch = useDispatch();

  const [queryParams, setQueryParams] = useUrlState({
    page: 0,
    pageSize: 10,
  });

  const query = {
    page: Number(queryParams.page),
    pageSize: queryParams.pageSize,
  };

  const permissionChecker = usePermissionChecker();

  const mailingListClient = new MailingListService();
  const mailingListData = useApiHook("MailingList", "getAllResources", {
    query,
  });

  const deleteMailingList = () => {
    if (!permissionChecker("delete-mailing-list", true)) return;

    mailingListClient.deleteManyResource({ data: selectedRows }).then((res) => {
      if (res?.data?.success) {
        dispatch(showSuccessAlert("Email Deleted"));
        mailingListData.refresh();
      } else {
        dispatch(showErrorAlert(res?.data?.message));
      }
    });
  };

  const handlePageChange = (e: any, value: any) => {
    setQueryParams({ page: value });
  };

  const handleRowsChange = (e: { target: { value: string } }) => {
    setQueryParams({ page: 0, pageSize: parseInt(e.target.value) });
  };

  const handleRowSelectionChange = (data: any) => {
    setSelectedRows(data);
  };

  return (
    <div className="container-fluid p-0">
      <GenericHeader
        // deleteResourceHandler={deleteMailingList}
        model="email"
      />
      <div className="p-4">
        <h2 className="mb-4">Mailing List</h2>
        <GenericTable
          showCheckbox={false}
          hideActionButton
          list={mailingListData?.data?.data ? mailingListData.data.data : []}
          totalCount={mailingListData?.data?.total}
          // onSelectedChange={handleRowSelectionChange}
          // selectedRows={selectedRows}
          onSelectedChange={null}
          selectedRows={[]}
          page={Number(queryParams.page)}
          loading={mailingListData.loading}
          rowsPerPage={Number(queryParams.pageSize)}
          onRowsChange={handleRowsChange}
          onPageChange={handlePageChange}
          image={placeholderPic}
          tableConfig={mailingList}
          model="mailing list"
          passRow={null}
        />
      </div>
    </div>
  );
};

export default MailingListTableView;
