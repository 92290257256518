import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { FaPlus } from "react-icons/fa6";

const NoResourceAvailable = ({
  resource,
  image,
  addButtonLine,
  message,
}: any) => {
  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        className="noProducts"
      >
        <img src={image} alt={resource} width="300px"></img>
      </Box>
      <Box my="30px">
        <Typography align="center" variant="h6" className="yellow">
          {message
            ? message
            : `There are no ${resource} in the system at the moment.`}{" "}
          <br />
          {addButtonLine && (
            <p style={{ marginTop: "5px" }}>
              Click{" "}
              <button className="iconButton mx-2">
                <FaPlus
                  style={{
                    display: "flex",
                    marginTop: "7px",
                    marginBottom: "7px",
                  }}
                  className="icon'"
                />
              </button>{" "}
              on the top right to add one!
            </p>
          )}
        </Typography>
      </Box>
    </>
  );
};

export default NoResourceAvailable;
