import Api from "./apiClient";

export default class ApiRequests extends Api {
  constructor(protected resource?: string) {
    super();
  }

  async postResource(
    payload: any,
    abortController = new AbortController()
  ): Promise<any> {
    return await this.doRequest(
      `${this.baseUrl}/${this.resource}`,
      payload,
      "POST",
      {
        signal: abortController.signal,
      }
    );
  }

  async postSpecificResource(
    suffix: string,
    payload: any,
    abortController = new AbortController()
  ): Promise<any> {
    return await this.doRequest(
      `${this.baseUrl}/${this.resource}/${suffix}`,
      payload,
      "POST",
      {
        signal: abortController.signal,
      }
    );
  }

  async getAllResources(
    payload: any,
    abortController = new AbortController()
  ): Promise<any> {
    return await this.doRequest(
      `${this.baseUrl}/${this.resource}`,
      payload,
      "GET",
      {
        signal: abortController.signal,
      }
    );
  }

  async getSpecificResource(
    id: string,
    payload: any,
    abortController = new AbortController()
  ): Promise<any> {
    return await this.doRequest(
      `${this.baseUrl}/${this.resource}/${id}`,
      payload,
      "GET",
      {
        signal: abortController.signal,
      }
    );
  }

  async deleteResource(
    payload: any,
    abortController = new AbortController()
  ): Promise<any> {
    return await this.doRequest(
      `${this.baseUrl}/${this.resource}`,
      payload,
      "DELETE",
      {
        signal: abortController.signal,
      }
    );
  }

  async deleteManyResource(
    payload: any,
    abortController = new AbortController()
  ): Promise<any> {
    return await this.doRequest(
      `${this.baseUrl}/${this.resource}`,
      payload,
      "DELETE",
      {
        signal: abortController.signal,
      }
    );
  }

  async patchResource(
    payload: any,
    abortController = new AbortController()
  ): Promise<any> {
    return await this.doRequest(
      `${this.baseUrl}/${this.resource}`,
      payload,
      "PATCH",
      {
        signal: abortController.signal,
      }
    );
  }

  async postImageResource(payload: any, type: string) {
    return await this.doRequest(
      `${this.baseUrl}/upload/${type}`,
      payload,
      "POST"
    );
  }
}
