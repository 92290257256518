import Box from "@mui/material/Box";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import createTheme from "@mui/material/styles/createTheme";

import { useMemo, useEffect } from "react";
import { BrowserRouter, Redirect, Switch, Route } from "react-router-dom";
import "./App.scss";
import ErrorAlert from "./components/Alerts/ErrorAlert/ErrorAlert";
import SuccessAlert from "./components/Alerts/SuccessAlert/SuccessAlert";
import ProtectedRoute from "./components/RouteComponents/ProtectedRoute";
import SessionRoute from "./components/RouteComponents/SessionRoute";
import Sidebar from "./components/Sidebar/Sidebar";
import useStoreSessionSelector from "./hooks/useStoreSessionSelector";
import Login from "./pages/login/Login";
import AddEditPropertyView from "./pages/Properties/AddEditPropertyView/AddEditPropertyView";
import Properties from "./pages/Properties/PropertiesTableView/Properties";
import AddEditUser from "./pages/Users/AddEditUser/AddEditUser";
import UsersTableView from "./pages/Users/UsersTableView/UsersTableView";
import AddEditBlock from "pages/Blocks/AddEditBlockView/AddEditBlockView";
import BlocksTableView from "pages/Blocks/BlocksTableView/BlocksTableView";
import theme from "./services/theme";
import { useDispatch } from "react-redux";
import useUserData from "hooks/useUserData";
import MailingListTableView from "pages/MailingList/MailingListTableView/MailingListTableView";
import Dashboard from "pages/dashboard/Dashboard";

const App = () => {
  const dispatch = useDispatch();
  // @ts-ignore
  const mainTheme = useMemo(() => createTheme(theme()), []);

  const session = useStoreSessionSelector();

  const user = useUserData();

  useEffect(() => {
    document.title =
      (process.env.REACT_APP_NAME ?? window["REACT_APP_NAME"]) || "";
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <ThemeProvider theme={mainTheme}>
        <ErrorAlert />
        <SuccessAlert />
        <BrowserRouter>
          {session.token && (
            <>
              <Sidebar />
            </>
          )}

          <Box sx={{ width: "100%", backgroundColor: "background.default" }}>
            <Switch>
              <Redirect exact from="/" to="/dashboard" />

              <SessionRoute
                exact
                path="/login"
                token={session.token}
                component={Login}
              />

              <ProtectedRoute
                exact
                path="/users"
                token={session.token}
                component={UsersTableView}
                user={user}
                allowedRoles={["admin"]}
              />

              <ProtectedRoute
                exact
                path="/users/add"
                token={session.token}
                component={AddEditUser}
                user={user}
                allowedRoles={["admin"]}
              />

              <ProtectedRoute
                exact
                path="/users/:id"
                token={session.token}
                component={AddEditUser}
                user={user}
                allowedRoles={["admin"]}
              />

              <ProtectedRoute
                exact
                path="/dashboard"
                token={session.token}
                component={Dashboard}
                user={user}
              />

              <ProtectedRoute
                exact
                path="/properties"
                token={session.token}
                component={Properties}
                user={user}
              />

              <ProtectedRoute
                exact
                path="/properties/add"
                token={session.token}
                component={AddEditPropertyView}
                user={user}
              />

              <ProtectedRoute
                exact
                path="/properties/:id"
                token={session.token}
                component={AddEditPropertyView}
                user={user}
              />

              <ProtectedRoute
                exact
                path="/blocks"
                token={session.token}
                component={BlocksTableView}
                user={user}
              />

              <ProtectedRoute
                exact
                path="/blocks/add"
                token={session.token}
                component={AddEditBlock}
                user={user}
              />

              <ProtectedRoute
                exact
                path="/blocks/:id"
                token={session.token}
                component={AddEditBlock}
                user={user}
              />

              <ProtectedRoute
                exact
                path="/mailing-list"
                token={session.token}
                component={MailingListTableView}
                user={user}
                allowedRoles={["admin"]}
              />

              {/* Whenever the path doesn't match with any route, redirect to home */}
              <Route>
                <Redirect to={"/"} />
              </Route>
            </Switch>
          </Box>
        </BrowserRouter>
      </ThemeProvider>
    </Box>
  );
};

export default App;
