import { store } from "../store";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import axiosFactory from "./AxiosInstance";
import { showErrorAlert } from "../store/actions/alerts";

export default class Api {
  baseUrl: string = "";

  constructor(protected resource?: string) {
    this.baseUrl =
      (process.env.REACT_APP_API_URL ?? window["REACT_APP_API_URL"]) +
      "/api/v1";
  }

  protected async doRequest(
    endpointPath: string,
    payload: any,
    method: string = "",
    options: any = {}
  ): Promise<AxiosResponse<any>> {
    let token = localStorage.getItem("ACCESS_TOKEN"); // (store.getState() as any).session?.token;
    const axiosInstance = axiosFactory();
    axiosInstance.interceptors.request.use(
      (request): AxiosRequestConfig => {
        if (token) {
          if (token) {
            // request.headers!["Authorization"] = `bearer ${token}`;
            request.headers!["Authorization"] = `${token}`;
          }
        }

        return request;
      },
      (error) => {
        store.dispatch(showErrorAlert(error));
        return error;
      }
    );

    axiosInstance.interceptors.response.use(
      (response): AxiosResponse<any> => {
        return response;
      },
      (error: AxiosError<{ message: string; error?: string }>) => {
        console.log("error:", error);
        let errorMessage = "An Error has occurred";
        let errorCode = -1;
        if (error.response?.status !== undefined) {
          errorCode = error.response?.status || 0;
          errorMessage =
            (error.response?.data?.message ?? error.response?.data?.error) ||
            "An Error has occurred";

          // handle invalid credentials
          if (errorCode === 404 && endpointPath.includes("login")) {
            errorCode = 422;
            errorMessage = "Invalid credentials";
            // console.log("BADD USER")
          }

          if (errorCode === 401 && /Invalid token/i.test(errorMessage)) {
            errorCode = 401;
            errorMessage = "token provided is invalid";
            token = "nonce";
          }
        } else if (error?.name === "Network Error") {
          errorCode = 0;
        }

        console.log({ errorCode, errorMessage });

        switch (errorCode) {
          // Not found
          case 404:
            store.dispatch(
              showErrorAlert({
                message:
                  "🕵️‍♂️ Entity not found! Make sure what you requested exists.",
              })
            );
            break;
          // Invalid Token or Unauthorized
          case 401:
            if (/token provided is invalid/i.test(errorMessage || "")) {
              if (token) {
                localStorage.removeItem("ACCESS_TOKEN");
                store.dispatch({
                  type: "END_TRADE_API_SESSION",
                });
                store.dispatch(
                  showErrorAlert({
                    message: "Your session has expired. \n Please Login again",
                  })
                );
                // window.location.reload();
              }
            } else {
              store.dispatch(showErrorAlert({ message: errorMessage }));
            }
            break;

          // Not connected to the internet or the server is down
          case 0:
            store.dispatch(
              showErrorAlert({
                message:
                  "Your internet connection seems to be connected or unstable. \n please make sure you are connected and try again",
              })
            );
            break;
          default:
            store.dispatch(
              showErrorAlert({
                message: errorMessage,
              })
            );
            break;
        }
        return Promise.reject(error);
      }
    );

    const formDataHeaders = {
      "Content-Type": "multipart/form-data",
    };

    switch (method) {
      default: // GET Method
        return await axiosInstance.get(`${endpointPath}`, {
          params: payload.query,
          signal: options.signal,
        });
      case "POST":
        console.log({
          headers: payload instanceof FormData ? formDataHeaders : undefined,
        });

        return await axiosInstance.post(`${endpointPath}`, payload, {
          headers: payload instanceof FormData ? formDataHeaders : undefined,
          signal: options.signal,
        });
      case "PUT":
        return await axiosInstance.put(
          `${endpointPath}/${payload.id}`,
          payload,
          {
            signal: options.signal,
          }
        );
      case "PATCH":
        if (payload instanceof FormData) {
          return await axiosInstance.patch(
            `${endpointPath}/${payload.get("id")}`,
            payload,
            {
              headers: formDataHeaders,
              signal: options.signal,
            }
          );
        } else {
          return await axiosInstance.patch(
            `${endpointPath}/${payload.id}`,
            payload,
            {
              signal: options.signal,
            }
          );
        }
      case "DELETE":
        if (payload.data) {
          return await axiosInstance.delete(`${endpointPath}`, {
            data: payload,
            signal: options.signal,
          });
        } else if (payload.id) {
          return await axiosInstance.delete(`${endpointPath}/${payload.id}`, {
            signal: options.signal,
          });
        } else {
          return await axiosInstance.delete(`${endpointPath}`, {
            data: payload,
            signal: options.signal,
          });
        }
    }
  }
}
