import React from "react";
import LoginCoverBackground from "./LoginCoverBackground";
import LoginForm from "./LoginForm";

interface LoginPCInterface {
  setEmail: any;
  setPassword: any;
  handleRememberMe: any;
  login: any;
  loading: boolean;
}

const LoginPC: React.FC<LoginPCInterface> = ({
  setEmail,
  setPassword,
  handleRememberMe,
  login,
  loading,
}) => {
  return (
    <div className="container-fluid p-0" style={{ height: "100vh" }}>
      <div className="row h-100">
        <div
          className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 login-container"
          style={{ padding: "100px" }}
        >
          <>
            <p style={{ color: "#1A1A1A" }}>Welcome Back</p>
            <h3 style={{ color: "#1A1A1A" }}>Login to your account</h3>
            <LoginForm
              setEmail={setEmail}
              setPassword={setPassword}
              login={login}
              loading={loading}
              handleRememberMe={handleRememberMe}
            />
          </>
        </div>
        <LoginCoverBackground />
      </div>
    </div>
  );
};

export default LoginPC;
