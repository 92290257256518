import { Route, Redirect } from "react-router-dom";

import Box from "@mui/material/Box";

import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";

interface IProps {
  component: any;
  token: string;
  exact: boolean;
  path: string;
  user?: any;
  allowedRoles?: string[];
}

const ProtectedRoute = ({
  component: Component,
  token,
  user,
  allowedRoles,
  ...rest
}: IProps) => {
  if (allowedRoles) {
    if (user?.loading) {
      return <LoadingIndicator />;
    }

    if (
      // !user.loading &&
      // user?.data?.result &&
      // user.data.result?.role !== "admin"
      user?.role !== "admin"
    ) {
      return <Redirect to="/" />;
    }

    return (
      <Route
        {...rest}
        render={(props) => {
          return token ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                minHeight: "100vh",
              }}
            >
              <Component {...props} />
            </Box>
          ) : (
            <Redirect to="/" />
          );
        }}
      />
    );
  }

  if (user.loading) {
    return <LoadingIndicator />;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return token ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minHeight: "100vh",
            }}
          >
            <Component {...props} />
          </Box>
        ) : (
          <Redirect to="/login" />
        );
      }}
    />
  );
};

export default ProtectedRoute;
