import React from "react";

import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";

type CenterType = {
  lng: number;
  lat: number;
};

type LocationMapProps = {
  center: CenterType;
  // setCenter: React.Dispatch<React.SetStateAction<CenterType>>;
  setCenter: any;
};

const LocationMap = ({ center, setCenter }: LocationMapProps) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: (process.env.REACT_APP_GOOGLE_MAPS_KEY ??
      window["REACT_APP_GOOGLE_MAPS_KEY"]) as string,
  });

  const handleMapDragEnd = React.useCallback(() => {
    const map = mapRef.current;
    if (map) {
      const newCenter = {
        lng: map?.getCenter()?.lng(),
        lat: map?.getCenter()?.lat(),
      };
      setCenter(newCenter);
    }
  }, [setCenter]);

  const mapRef = React.useRef<google.maps.Map | null>(null);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{ width: "100%", height: "100%" }}
      center={center}
      zoom={15}
      onLoad={(map) => {
        mapRef.current = map;
      }}
      onDragEnd={handleMapDragEnd}
      options={{
        streetViewControl: false,
        fullscreenControl: false,
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -100%)",
          width: "27px",
          minHeight: "50px",
          background:
            "url(https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi3.png)",
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
          pointerEvents: "none",
          zIndex: 1,
        }}
      ></div>
    </GoogleMap>
  ) : (
    <></>
  );
};

export default React.memo(LocationMap);
