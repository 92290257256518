import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';

import "./PieChart.scss";
import { Typography } from '@mui/material';

export interface PieChartProps {
    data: {
        id: number;
        value: number;
        label: string;
    }[];
    title: string
};

export default function Pie({ data, title, ...props }: PieChartProps) {

    let chartWidth = 500;

    if (window.innerWidth <= 576) {
        chartWidth = 300;
    } else if (window.innerWidth <= 820) {
        chartWidth = 600;
    }

    return (
        <div className='p-2 chart-bg' style={{ height: '350px' }}>
            <Typography variant='body2' mb={1} mt={1}>
                {title}
            </Typography>
            <PieChart
                series={[
                    {
                        data: data
                    },
                ]}
                width={chartWidth}
                sx={{ "& .MuiChartsLegend-series text": { fontSize: "14px !important" }, }}
            />
        </div>


    );
}