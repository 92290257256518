import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import React, { FormEvent } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import GenericHeader from "../../../components/GenericHeader/GenericHeader";
import AddEditUserForm from "../../../components/PageComponents/Users/AddEditUserForm";
import useScreenSize from "../../../hooks/useScreenSize";
import useStoreSessionSelector from "../../../hooks/useStoreSessionSelector";
import UserService from "../../../services/Entities/UserService";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../store/actions/alerts";
import "./AddEditUser.scss";
import { usePermissionChecker } from "hooks/useRoles";
import { getUser } from "store/actions/user";

const AddEditUser = () => {
  const [formState, setFormState] = React.useState("create");
  const [object, setObject] = React.useState({
    name: "",
    role: "",
    email: "",
    password: "",
  });
  const [password, setPassword] = React.useState("");
  const [tempPassword, setTempPassword] = React.useState("");
  const [userRole, setUserRole] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const usersClient = new UserService();
  const session = useStoreSessionSelector();
  const { id }: any = useParams();
  const history = useHistory();
  const device = useScreenSize();
  const dispatch = useDispatch();

  const permissionChecker = usePermissionChecker();

  const deleteResourceHandler = () => {
    if (!permissionChecker("delete-user")) return;

    setLoading(true);

    if (!id) {
      dispatch(
        showErrorAlert({
          message: "There is no user to delete! You are in create-mode.",
        })
      );
    } else {
      const payload = {
        id: id,
        token: session.token,
      };
      usersClient
        .deleteResource(payload)
        .then((res) => {
          if (res?.data?.success) {
            dispatch(showSuccessAlert("User deleted"));
            history.push("/users");
          } else {
            dispatch(showErrorAlert(res?.data?.message));
          }
        })
        .finally(() => setLoading(false));
    }
  };

  const fetchSpecificResource = () => {
    setLoading(true);

    usersClient
      .getSpecificResource(id, {})
      .then((res) => {
        setFormState("edit");
        setObject(res["data"]["data"][0]);
        setUserRole(res["data"]["data"][0].role);
      })
      .finally(() => setLoading(false));
  };

  const submitResource = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (formState === "create") {
      if (!permissionChecker("add-user", true)) return;

      setLoading(true);

      const payload = {
        ...object,
        role: userRole,
      };

      usersClient
        .postResource(payload)
        .then((res) => {
          if (res?.data?.success) {
            dispatch(showSuccessAlert("Submitted Successfully!"));
            window.location.href = "/users";
          } else {
            dispatch(showErrorAlert(res?.data));
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      if (!permissionChecker("edit-user", true)) return;

      setLoading(true);

      const payload = {
        id: id,
        ...object,
        role: userRole,
      };

      usersClient
        .patchResource(payload)
        .then((res) => {
          if (res?.data?.success) {
            dispatch(showSuccessAlert("User Updated"));
          } else {
            dispatch(showErrorAlert(res?.data?.message));
          }
          // setObject(res["data"]["data"][0]);
          fetchSpecificResource();
          dispatch(getUser());
        })
        .finally(() => setLoading(false));
    }
  };

  React.useEffect(() => {
    if (id) {
      fetchSpecificResource();
    }
  }, [id]);

  const isEmailValid = (email: string) => {
    let input = document.createElement("input");

    input.type = "email";
    input.required = true;
    input.value = email;

    if (input.checkValidity()) {
      return true;
    } else {
      return false;
    }
  };

  const isPasswordValid = (password: string, tempPassword: string) => {
    if (!password && !tempPassword) {
      return true;
    }

    if (password.length > 7 && tempPassword.length > 7) {
      if (password === tempPassword) {
        return true;
      }
    }
  };

  return (
    <>
      <GenericHeader
        deleteResourceHandler={
          formState === "edit" ? deleteResourceHandler : undefined
        }
        model="user"
      />
      <form onSubmit={submitResource}>
        <Box display="flex" flexDirection="column" mx={4} my={6} gap={2}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5" color="text.primary">
              {formState === "create" ? "Add" : "Edit"} User
            </Typography>
            <Button
              disableElevation
              color="primary"
              variant="contained"
              sx={{
                minWidth: "100px",
                maxHeight: "50px",
                py: 1.5,
                px: { xs: 1, md: 2 },
              }}
              type="submit"
              disabled={
                !isEmailValid(object?.email) ||
                !isPasswordValid(password, tempPassword) ||
                !object?.role
              }
            >
              {loading ? (
                <CircularProgress style={{ color: "#fff" }} />
              ) : (
                <>{formState === "create" ? "Add" : "Update"} User</>
              )}
            </Button>
          </Box>

          <AddEditUserForm
            object={object}
            setObject={setObject}
            isEmailValid={isEmailValid}
            password={password}
            setPassword={setPassword}
            tempPassword={tempPassword}
            setTempPassword={setTempPassword}
            userRole={userRole}
            setUserRole={setUserRole}
            loading={loading}
          />
        </Box>
      </form>
    </>
  );
};

export default AddEditUser;
