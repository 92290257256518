import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import { FaEye, FaEyeSlash } from "react-icons/fa";

import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

import React from "react";

interface PasswordFieldsInterface {
  password: string;
  setPassword: any;
  tempPassword: string;
  setTempPassword: any;
}

const PasswordFields: React.FC<PasswordFieldsInterface> = ({
  password,
  setPassword,
  tempPassword,
  setTempPassword,
}) => {
  const [showOriginalPassword, setShowOriginalPassword] = React.useState(false);
  const [showConfirmationPassword, setShowConfirmationPassword] =
    React.useState(false);
  return (
    <React.Fragment>
      <TextField
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                onClick={() => setShowOriginalPassword(!showOriginalPassword)}
              >
                {showOriginalPassword ? <FaEyeSlash /> : <FaEye />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        fullWidth
        size="small"
        sx={{ minWidth: "80%" }}
        className="formInput"
        label="Password"
        name="password"
        type={showOriginalPassword ? "text" : "password"}
        value={tempPassword ? tempPassword : ""}
        onChange={(e) => setTempPassword(e.target.value)}
      />
      <TextField
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                onClick={() =>
                  setShowConfirmationPassword(!showConfirmationPassword)
                }
              >
                {showConfirmationPassword ? <FaEyeSlash /> : <FaEye />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        fullWidth
        size="small"
        sx={{ minWidth: "80%" }}
        className="formInput"
        label="Confirm Password"
        name="password"
        type={showConfirmationPassword ? "text" : "password"}
        value={password ?? ""}
        onChange={(e) => setPassword(e.target.value)}
      />

      <Stack gap={1}>
        {tempPassword !== password && (
          <Typography variant="body2" className="text-danger">
            Both passwords must be matching!
          </Typography>
        )}
        {tempPassword !== "" && tempPassword.length < 8 && (
          <Typography variant="body2" className="text-danger">
            Password should be atleast 8 characters long
          </Typography>
        )}
      </Stack>
    </React.Fragment>
  );
};

export default PasswordFields;
