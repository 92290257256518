import { combineReducers } from "redux";
import alerts from "./alerts";
import user from "./user";
import session from "./session";
import qrmodal from "./qrmodal";
import sidebar from "./sidebar";

const rootReducer = combineReducers({
  session,
  user,
  alerts,
  qrmodal,
  sidebar,
});

export default rootReducer;
