import React from 'react'

import Typography from "@mui/material/Typography";
import { IconType } from "react-icons";

import "./CountCard.scss";

export interface CountCardProps {
    icon: IconType;
    count: number;
    title: string;
}

export default function CountCard({ icon: Icon, count, title, ...props }: CountCardProps): JSX.Element {
    return (
        <div className='px-4 py-4 h-100 w-100 card-style'>
            <div>
                <Icon color='#1994FF' size={60} />
            </div>
            <div className='card-text'>
                <Typography variant='h5'>
                    {count}
                </Typography>
                <Typography variant='body2'>
                    {title}
                </Typography>
            </div>

        </div>
    )
}
