import { useHistory } from "react-router-dom";

import Button from "@mui/material/Button";

import WestIcon from "@mui/icons-material/West";

import "./GoBackButton.scss";

const GoBackButton = () => {
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };
  return (
    <Button
      disableElevation
      variant="contained"
      startIcon={<WestIcon />}
      onClick={goBack}
      sx={{
        boxShadow: "0px 6px 13px 0px #00000005",
        color: "secondary.light",
        backgroundColor: "background.paper",
        "&:hover": {
          backgroundColor: "background.paperDark",
        },
      }}
    >
      Back
    </Button>
  );
};

export default GoBackButton;
