import React, { useEffect, useState } from 'react'

import CountCard, { CountCardProps } from 'components/CountCard/CountCard'
import GenericHeader from 'components/GenericHeader/GenericHeader'
import { Grid } from '@mui/material'
import {
    FaHouse,
    FaUsers,
    FaCube,
} from "react-icons/fa6";
import { useApiHook } from 'providers/ApiProvider';

import "./Dashboard.scss";
import Pie, { PieChartProps } from 'components/PieChart/Pie';
import Bar, { BarChartData } from 'components/BarChart/Bar';
import PropertyService from 'services/Entities/PropertyService';
import { BiCreditCardFront } from 'react-icons/bi';
import BarChart from 'components/BarChart/Bar';



const Dashboard = () => {

    const [propertyTypes, setPropertyTypes] = useState<string[]>([]);
    const [availabilityStatus, setAvailabilityStatus] = useState<string[]>([]);

    const usersData = useApiHook("Users", "getAllResources", { query: { page: 0, pageSize: 10 } });
    const propertiesData = useApiHook("Property", "getAllResources", { query: { page: 0, pageSize: 10 } });
    const blocksData = useApiHook("Blocks", "getAllResources", { query: { page: 0, pageSize: 10 } });
    const propertiesClient = new PropertyService();

    const getPropertyTypes = () => {

        propertiesClient
            .getSpecificResource("property_types", {})
            .then((response: any) => {
                setPropertyTypes(response.data.data);
            })
            .finally(() => {
            });
    };

    const getAvailabilityStatuses = () => {
        propertiesClient
            .getSpecificResource("availability_statuses", {})
            .then((response: any) => {
                setAvailabilityStatus(response.data.data);
                console.log('req res', response.data.data)
            })
            .finally(() => {
            });
    };

    const propertyTypeStats: PieChartProps['data'] = propertyTypes.map((type, index) => ({
        id: index,
        value: propertiesData.data?.data.filter(item => item.property_type === type).length,
        label: type
    }));

    const propertyAvailabilityStats = availabilityStatus.map((status, index) => (
        propertiesData.data?.data.filter(item => item.availability_status === status).length
    ))

    const countData: CountCardProps[] = [
        {
            title: 'Users',
            count: usersData.data?.total,
            icon: FaUsers
        },
        {
            title: 'Properties',
            count: propertiesData.data?.total,
            icon: FaHouse
        },
        {
            title: 'Blocks',
            count: blocksData.data?.total,
            icon: FaCube
        }
    ]

    const barData: BarChartData['data'] = {
        labels: availabilityStatus,
        datasets: [
            {
                data: propertyAvailabilityStats,
                backgroundColor: 'rgb(2, 178, 175)'
            },
        ],
    };

    useEffect(() => {
        getPropertyTypes();
        getAvailabilityStatuses();
    }, []);


    return (
        <div className="container-fluid p-0">

            <GenericHeader
                model="dashboard"
            />

            <div className="p-4">
                <h2 className="mb-4">Dashboard</h2>

                <Grid container spacing={4}>

                    {countData.map((card, index) => {
                        return (
                            <Grid item key={index} sm={4} xs={12}>
                                <div >
                                    <CountCard icon={card.icon} title={card.title} count={card.count} />
                                </div>
                            </Grid>

                        )
                    })}

                    <Grid item md={5} xs={12}>
                        <Pie data={propertyTypeStats} title='Property Types' />
                    </Grid>

                    <Grid item md={7} xs={12}>
                        <BarChart data={barData} title='Availability Status' />
                    </Grid>

                </Grid>
            </div>
        </div>
    )
}

export default Dashboard