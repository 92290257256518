import React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { useRoles } from "hooks/useRoles";

const UserRoles = ({ userRole, setUserRole }: any) => {
  const roles = useRoles();
  return (
    <Box display="flex" gap={2} flexWrap="wrap">
      {roles.map((role, index) => (
        <Button
          disableElevation
          key={index}
          onClick={() => setUserRole(role.value)}
          variant={userRole === role.value ? "contained" : "outlined"}
        >
          {role.label}
        </Button>
      ))}
    </Box>
  );
};

export default UserRoles;
