import { useState, useEffect } from "react";
import { useDebounce } from "use-debounce";

import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";

import GoBackButton from "../GoBackButton/GoBackButton";
import PageHeader from "../PageHeader";
import { ConfirmDialog } from "../Dialogs";

import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";

import { useDispatch } from "react-redux";
import { toggleSidebar } from "store/actions/sidebar";

import "./GenericHeader.scss";

const GenericHeader = ({
  selectedRows,
  onSearch,
  model,
  addResourceHandler,
  deleteResourceHandler,
}: any) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [value] = useDebounce(query, 1000);

  const initConfirmConfig = {
    title: "",
    body: "",
    visible: false,
  };

  useEffect(() => {
    if (onSearch) {
      onSearch(value);
    }
  }, [value]);

  const [confirmConfig, setConfirmConfig] = useState(initConfirmConfig);

  const confirmDelete = () => {
    setConfirmConfig({
      title: "Please Confirm" || "",
      body: `Are you sure you want to delete this ${model}?` || "",
      visible: true,
    });
  };

  return (
    <PageHeader>
      <>
        <Box display="flex" gap={2}>
          <IconButton
            onClick={() => dispatch(toggleSidebar())}
            sx={{
              backgroundColor: "background.paper",
              boxShadow: "0px 6px 13px 0px #00000005",
              color: "secondary.light",
            }}
          >
            <FormatListBulletedIcon />
          </IconButton>
          <GoBackButton />
        </Box>
        <Box display="flex" alignItems="center" gap={2}>
          {onSearch && (
            <TextField
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "primary.main",
                        p: 0.5,
                        borderRadius: "2px",
                        mr: -1,
                      }}
                    >
                      <SearchIcon
                        fontSize="medium"
                        sx={{
                          color: "white.main",
                        }}
                      />
                    </Box>
                  </InputAdornment>
                ),
              }}
              fullWidth
              placeholder="Search..."
              variant="outlined"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              sx={{
                boxShadow: "0px 6px 13px 0px #00000005",
                backgroundColor: "background.paper",
                "& fieldset": { border: "none" },
              }}
            />
          )}

          <Box display="flex" alignItems="center" gap={1}>
            <ConfirmDialog
              setConfirmConfig={setConfirmConfig}
              confirmConfig={confirmConfig}
              executeActionCallback={deleteResourceHandler}
            />
            {addResourceHandler && (
              <IconButton
                onClick={addResourceHandler}
                sx={{
                  backgroundColor: "background.paper",
                  borderRadius: "2px",
                  boxShadow: "0px 6px 13px 0px #00000005",
                }}
              >
                <AddCircleIcon color="primary" />
              </IconButton>
            )}
            {deleteResourceHandler && (
              <IconButton
                onClick={confirmDelete}
                disabled={selectedRows && !selectedRows?.length}
                sx={{
                  backgroundColor: "background.paper",
                  borderRadius: "2px",
                  boxShadow: "0px 6px 13px 0px #00000005",
                }}
              >
                <DeleteIcon
                  color={
                    selectedRows && !selectedRows?.length
                      ? "disabled"
                      : "primary"
                  }
                />
              </IconButton>
            )}
          </Box>
        </Box>
      </>
    </PageHeader>
  );
};

export default GenericHeader;
