import { useState } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AddPropertyInBlockModal from "./AddPropertyInBlockModal";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";

import { IBlock } from "interfaces/Blocks";

interface AddEditBlockFormProps {
  object: IBlock;
  setObject: React.Dispatch<React.SetStateAction<IBlock>>;
  loading: boolean;
}

const AddEditBlockForm = ({
  object,
  setObject,
  loading,
}: AddEditBlockFormProps) => {
  const [addPropertyDialog, setAddPropertyDialog] = useState(false);

  const handleChange = (input: any) => {
    let tempObj: IBlock = object;
    tempObj[input.name] = input.value;
    setObject({ ...tempObj });
  };

  const handleRemoveProperty = (propertyId: string) => {
    const newProperties = object.properties.filter(
      (property) => property._id !== propertyId
    );
    setObject({ ...object, properties: newProperties });
  };

  return (
    <>
      <Box display="flex" flexDirection="column" gap={4}>
        {loading && (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              backgroundColor: "background.paper",
              opacity: 0.8,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "fixed",
              top: 0,
              left: 0,
              zIndex: 1,
            }}
          >
            <LoadingIndicator />
          </Box>
        )}

        <Box display="flex" flexDirection={{ xs: "column", md: "row" }} gap={3}>
          <Box
            display="flex"
            flexDirection="column"
            py={4}
            px={3}
            pb={3}
            gap={3}
            sx={{
              width: { xs: "100%", md: "60%" },
              backgroundColor: "background.paper",
            }}
          >
            <Typography variant="body1" color="text.secondary">
              Block Details
            </Typography>

            <TextField
              required
              variant="outlined"
              size="small"
              label="Name"
              sx={{ minWidth: "80%" }}
              className="formInput"
              name="name"
              value={object ? object.name : ""}
              onChange={(e) => handleChange(e.target)}
            />

            <TextField
              required
              multiline
              rows={4}
              variant="outlined"
              size="small"
              label="Description"
              sx={{ maxWidth: "100%" }}
              className="formInput"
              name="description"
              value={object ? object.description : ""}
              onChange={(e) => handleChange(e.target)}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            py={4}
            px={3}
            pb={3}
            gap={3}
            sx={{
              width: { xs: "100%" },
              backgroundColor: "background.paper",
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="body1" color="text.secondary">
                Properties in Block
              </Typography>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                onClick={() => {
                  setAddPropertyDialog(true);
                }}
                endIcon={<AddCircleIcon />}
              >
                Add Properties
              </Button>
            </Box>
            {!(object?.properties?.length > 0) ? (
              <Typography variant="body2" color="text.secondary">
                No property exists in this block yet.
              </Typography>
            ) : (
              <TableContainer>
                <Table>
                  <TableHead sx={{ background: "#F6F7FC" }}>
                    <TableRow>
                      <TableCell>Title</TableCell>
                      <TableCell>Type of Property</TableCell>
                      <TableCell>Type of Building</TableCell>
                      <TableCell>Availability</TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell>Area</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {object.properties.map((property) => (
                      <TableRow key={property._id}>
                        <TableCell>{property.title}</TableCell>
                        <TableCell>{property.property_type}</TableCell>
                        <TableCell>{property.building_type}</TableCell>
                        <TableCell>{property.availability_status}</TableCell>
                        <TableCell>{property.price}</TableCell>
                        <TableCell>{property.area}</TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => {
                              handleRemoveProperty(property._id ?? "");
                            }}
                            color="error"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        </Box>
      </Box>

      {addPropertyDialog && (
        <AddPropertyInBlockModal
          addPropertyDialog={addPropertyDialog}
          setAddPropertyDialog={setAddPropertyDialog}
          object={object}
          setObject={setObject}
        />
      )}
    </>
  );
};

export default AddEditBlockForm;
