import { TableConfig } from "interfaces/tableConfig";

const users: TableConfig[] = [
  {
    heading: "Name",
    attribute: "name",
    type: "string",
    sortable: true,
    selected: true,
  },
  {
    heading: "Role",
    attribute: "role",
    type: "string",
    sortable: true,
    selected: true,
  },
  {
    heading: "Email",
    attribute: "email",
    type: "string",
    sortable: true,
    selected: true,
  },
  {
    heading: "MFA Enabled",
    attribute: "MFA_enabled",
    type: "boolean",
    sortable: true,
    selected: true,
  },
];

export default users;
