import { useState } from "react";

import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";

import { FaEllipsisVertical, FaGripLines } from "react-icons/fa6";

interface ColumnOptionsInterface {
  tableConfig: any;
  setTableConfig: any;
  model: string;
}

const ColumnOptions = ({
  tableConfig,
  setTableConfig,
  model,
}: ColumnOptionsInterface) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openColumnSelector = Boolean(anchorEl);

  const handleColumnSelector = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeCloumnSelector = () => {
    setAnchorEl(null);
  };

  const toggleColumns = (attribute: string) => {
    const attributeIndex = tableConfig.findIndex(
      (config: any) => config.attribute === attribute
    );

    if (attributeIndex !== -1) {
      const updatedTableConfig = [...tableConfig];
      updatedTableConfig[attributeIndex].selected =
        !updatedTableConfig[attributeIndex].selected;

      setTableConfig(updatedTableConfig);
      localStorage.setItem(
        model + "TableConfig",
        JSON.stringify(updatedTableConfig)
      );
    }
  };

  const [draggedItemIndex, setDraggedItemIndex] = useState<any>(null);
  const [dropTargetIndex, setDropTargetIndex] = useState<any>(null);

  const handleDragStart = (e: any, index: number) => {
    e.dataTransfer.setData("text/plain", index.toString());
    setDraggedItemIndex(index);
  };

  const handleDragOver = (e: any, index: number) => {
    e.preventDefault();
    setDropTargetIndex(index);
  };

  const handleDrop = (e: any, index: number) => {
    e.preventDefault();

    if (draggedItemIndex === null || draggedItemIndex === index) {
      return;
    }

    const newMenuItems = [...tableConfig];
    const draggedItem = newMenuItems[draggedItemIndex];

    newMenuItems.splice(draggedItemIndex, 1);
    newMenuItems.splice(index, 0, draggedItem);

    localStorage.setItem(model + "TableConfig", JSON.stringify(newMenuItems));
    setTableConfig(newMenuItems);
    setDraggedItemIndex(null);
  };

  return (
    <>
      <IconButton
        title="Toggle Columns"
        aria-label="toggle columns"
        id="long-button"
        aria-controls={openColumnSelector ? "long-menu" : undefined}
        aria-expanded={openColumnSelector ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleColumnSelector}
      >
        <FaEllipsisVertical />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={openColumnSelector}
        onClose={closeCloumnSelector}
      >
        {tableConfig.map((column: any, index: number) => {
          return (
            <MenuItem
              draggable
              onDragStart={(e) => handleDragStart(e, index)}
              onDragOver={(e) => handleDragOver(e, index)}
              onDrop={(e) => handleDrop(e, index)}
              key={index}
              className="px-3"
              style={{
                padding: "0",
                backgroundColor:
                  dropTargetIndex === index ? "lightgray" : "transparent",
              }}
              onClick={() => {
                toggleColumns(column.attribute);
              }}
            >
              <div className="d-flex justify-content-between align-items-center w-100">
                <div className="me-4">
                  <FaGripLines className="me-2" style={{ cursor: "grab" }} />
                  <span>{column.heading}</span>
                </div>
                <Checkbox checked={column.selected} className="" />
              </div>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default ColumnOptions;
