import { store } from "./../index";
import { AxiosError } from "axios";
import AuthenticationService from "../../services/Entities/AuthenticationService";
import { getUser, unsetUser } from "./user";
import { showErrorAlert, showSuccessAlert } from "./alerts";

export const START_SESSION = "START_TRADE_API_SESSION";
export const END_SESSION = "END_TRADE_API_SESSION";

export const startSession = (token: any, history: any) => {
  return async (dispatch: any) => {
    if (!token) return;

    localStorage.setItem("ACCESS_TOKEN", token);

    const action = {
      type: START_SESSION,
      payload: token,
    };
    // postActionLogin(token, history);
    store.dispatch(action);
  };
};

export const endSession = () => {
  return (async (dispatch: any) => {
    const action = {
      type: END_SESSION,
    };
    dispatch(action);
  }) as any;
};

export const logout = () => {
  return (async (dispatch: any) => {
    localStorage.removeItem("ACCESS_TOKEN");

    const action = {
      type: END_SESSION,
    };

    dispatch(action);
    dispatch(unsetUser());

    dispatch(showSuccessAlert("Logged out successfully"));
  }) as any;
};

export const postActionLogin = async (token: string, history: any) => {
  if (token) {
    history.push("/");
  }
};

export const loginUser = (
  payload: any,
  setLoading: any,
  history: any,
  getImgUrl: any,
  detectErr: any,
  onSuccess?: any
) => {
  return async (dispatch: any) => {
    try {
      const authApi = new AuthenticationService();
      const responseData = await authApi.postSpecificResource("login", payload);
      if (responseData.data.token) {
        dispatch(startSession(responseData.data.token, history));
        dispatch(getUser());
        // setTimeout(() => {
        //   onSuccess?.(responseData.data.token)
        // }, 10000)
      } else if (responseData.data.multiFactorAuthentication) {
        getImgUrl(responseData.data.qrCode);
        detectErr(false);
      } else {
        dispatch(showErrorAlert(responseData.data));
      }
      setLoading(false);
    } catch (e: AxiosError | any) {
      setLoading(false);
      detectErr(true);
    }
  };
};
