import { Dialog, DialogTitle, DialogContent, DialogContentText, IconButton } from '@mui/material'
import {
    FaX
} from "react-icons/fa6";
import React from 'react'

export default function BlockDialog({ config, setConfig, ...props }) {
    const handleClose = () => {
        setConfig({
            ...config,
            visible: false,
        });
    };

    return (
        <Dialog
            PaperProps={{ style: { maxHeight: '85vh' } }}

            onClose={handleClose} open={config.visible}>
            <div className='d-flex justify-content-between align-items-center' style={{ paddingRight: '10px' }}>
                <DialogTitle>{config.title}</DialogTitle>
                <IconButton onClick={() => handleClose()}>
                    <FaX size={15} />
                </IconButton>
            </div>

            <DialogContent>
                <DialogContentText color="textPrimary">
                    {config.body}
                </DialogContentText>
            </DialogContent>

        </Dialog>

    )
}
