import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    defaults
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Typography } from '@mui/material';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: false,
            text: '',
        },

    },
};

defaults.font.size = 14;
defaults.font.weight = 400;
defaults.font.family = 'Poppins'

export interface BarChartData {
    data: {
        labels: string[];
        datasets: {
            data: number[];
            backgroundColor: string;
        }[];
    }
    title: string;
}

const BarChart = ({ data, title, ...props }: BarChartData) => {
    return (
        <div className='p-3 chart-bg' style={{ height: '350px' }}>
            <Typography variant='body2' mt={1}>
                {title}
            </Typography>
            <Bar options={options} data={data} />

        </div>
    )
}

export default BarChart;
