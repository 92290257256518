import { TableConfig } from "interfaces/tableConfig";

const blocksTable: TableConfig[] = [
  {
    heading: "Name",
    attribute: "name",
    type: "string",
    sortable: true,
    selected: true,
  },
  {
    heading: "Description",
    attribute: "description",
    type: "string",
    sortable: true,
    selected: true,
  },
  {
    heading: "Property Count",
    attribute: "properties",
    type: "array",
    sortable: true,
    selected: true,
  },
  {
    heading: "Created At",
    attribute: "created_at",
    type: "date",
    sortable: true,
    selected: true,
  },
  {
    heading: "Updated At",
    attribute: "updated_at",
    type: "date",
    sortable: true,
    selected: true,
  },
];

export default blocksTable;
