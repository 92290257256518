import { createTheme, type ThemeOptions } from "@mui/material/styles";

const theme = () => {
  return {
    palette: {
      mode: "light",
      primary: {
        main: "#1994FF",
        light: "#47A9FF",
        dark: "#1167B2",
      },
      secondary: {
        main: "#292E42",
        light: "#BEC3E5",
      },
      text: {
        primary: "#292E42",
        secondary: "#838CA5",
        light: "rgba(255, 255, 255, 1)",
      },
      white: {
        main: "#FFFFFF",
        contrastText: "#838CA5",
      },
      background: {
        default: "#F6F7FC",
        paper: "#FFFFFF",
        paperDark: "#ECEDF2",
        darkShade: "#F0F1F9",
      },
    },
    typography: {
      fontFamily: "Poppins",
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightSemiBold: 600,
      fontWeightBold: 700,
    },
  };
};

export default theme as ThemeOptions;
