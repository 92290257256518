import { showErrorAlert } from "./alerts";
import AuthenticationService from "services/Entities/AuthenticationService";

export const REMOVE_USER = "REMOVE_USER_ACTION";
export const SET_USER = "SET_USER_ACTION";

export const unsetUser = () => {
  return {
    type: REMOVE_USER,
  };
};

export const getUser = () => {
  return async (dispatch) => {
    try {
      const authClient = new AuthenticationService();
      authClient.getSpecificResource("me", {}).then((response) => {
        if (response?.data?.success) {
          const action = {
            type: SET_USER,
            payload: response?.data?.result,
          };

          dispatch(action);
        }
      });
    } catch (e) {
      dispatch(showErrorAlert(e));
    }
  };
};
