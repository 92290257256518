import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import useAmenities from "../../../../hooks/useAmenities";

const Amenities = ({ amenities, setAmenities }: any) => {
  const staticAmenities = useAmenities();

  const handleAmenitiesSelect = (selectedAmenity: string) => {
    let tempAmenities = [...amenities];
    if (!tempAmenities.includes(selectedAmenity)) {
      tempAmenities.push(selectedAmenity);
    } else {
      var filteredAmenities = tempAmenities.filter(function (value) {
        return value !== selectedAmenity;
      });
      tempAmenities = [...filteredAmenities];
    }
    setAmenities([...tempAmenities]);
  };

  return (
    <Box display="flex" gap={1} flexWrap="wrap">
      {staticAmenities.map((amenity, index) => (
        <Button
          disableElevation
          size="small"
          key={index}
          onClick={() => handleAmenitiesSelect(amenity)}
          variant={amenities.includes(amenity) ? "contained" : "outlined"}
        >
          {amenity}
        </Button>
      ))}
    </Box>
  );
};

export default Amenities;
