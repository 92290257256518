export interface AreState {
  session: {
    token: string;
  };
  user: {
    _id: string;
    email: string;
    role: string;
  };
  alerts: {
    success: {
      message: string;
      open: boolean;
    };
    error: {
      message: string;
      open: boolean;
    };
  };
  qr: {
    imgUrl: string;
    email: string;
    password: string;
  };
  sidebar: {
    collapsed: boolean;
  };
}

const initialState: AreState = {
  session: {
    token: "",
  },
  user: {
    _id: "",
    email: "",
    role: "",
  },
  alerts: {
    success: {
      message: "",
      open: false,
    },
    error: {
      message: "",
      open: false,
    },
  },
  qr: {
    imgUrl: "",
    email: "",
    password: "",
  },
  sidebar: {
    collapsed: false,
  },
};

export default initialState;
