import { TableConfig } from "../interfaces/tableConfig";

const propertiesTableConfig: TableConfig[] = [
  {
    heading: "Title",
    attribute: "title",
    type: "string",
    sortable: true,
    selected: true,
  },
  {
    heading: "Description",
    attribute: "description",
    type: "string",
    sortable: false,
    selected: false,
  },
  {
    heading: "Type of Property",
    attribute: "property_type",
    type: "string",
    sortable: true,
    selected: true,
  },
  {
    heading: "Type of Building",
    attribute: "building_type",
    type: "string",
    sortable: true,
    selected: true,
  },
  {
    heading: "Availability",
    attribute: "availability_status",
    type: "string",
    sortable: true,
    selected: true,
  },
  {
    heading: "Price",
    attribute: "price",
    type: "currency",
    sortable: true,
    selected: true,
  },
  {
    heading: "Area",
    attribute: "area",
    type: "string",
    sortable: true,
    selected: true,
  },
  {
    heading: "Total Rooms",
    attribute: "room_count",
    type: "string",
    sortable: true,
    selected: false,
  },
  {
    heading: "Bedrooms",
    attribute: "bedroom_count",
    type: "string",
    sortable: true,
    selected: false,
  },
  {
    heading: "Bathrooms",
    attribute: "bathroom_count",
    type: "string",
    sortable: true,
    selected: false,
  },
  {
    heading: "Plot Size",
    attribute: "plot_size",
    type: "string",
    sortable: true,
    selected: true,
  },
  {
    heading: "Featured",
    attribute: "featured_status",
    type: "boolean",
    sortable: true,
    selected: true,
  },
];

export default propertiesTableConfig;
