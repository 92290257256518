import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import placeholderPic from "../../../assets/no_data_x.gif";
import GenericHeader from "../../../components/GenericHeader/GenericHeader";
import GenericTable from "../../../components/GenericTable/GenericTable";
import propertiesTableConfig from "../../../configs/propertiesTable";
import useStoreSessionSelector from "../../../hooks/useStoreSessionSelector";
import useUrlState from "@ahooksjs/use-url-state";
import { useApiHook } from "providers/ApiProvider";
import { usePermissionChecker } from "hooks/useRoles";
import PropertyService from "services/Entities/PropertyService";
import { useDispatch } from "react-redux";
import { showErrorAlert, showSuccessAlert } from "store/actions/alerts";

const Properties = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const permissionChecker = usePermissionChecker();

  const [searchQuery, setSearchQuery] = useState(
    localStorage.getItem("searchQuery") || ""
  );
  const [selectedRows, setSelectedRows] = useState([]);

  const [queryParams, setQueryParams] = useUrlState({
    q: searchQuery ?? "",
    page: 0,
    pageSize: 10,
    // sortBy: "releaseDate",
    // sortDirection: "desc",
  });

  const query = {
    title: queryParams.q,
    page: Number(queryParams.page),
    pageSize: queryParams.pageSize,
  };

  const propertiesClient = new PropertyService();
  const propertiesData = useApiHook("Property", "getAllResources", { query });

  const handleSearch = (value: string) => {
    localStorage.setItem("searchQuery", value);
    setSearchQuery(value);

    if (queryParams.q !== value) {
      setQueryParams({ q: value, page: 0 });
    } else {
      setQueryParams({ q: value });
    }
  };

  const deleteProperty = () => {
    if (!permissionChecker("delete-property", true)) return;

    propertiesClient.deleteManyResource({ data: selectedRows }).then((res) => {
      if (res?.data?.success) {
        dispatch(showSuccessAlert("Property Deleted"));
        propertiesData.refresh();
      } else {
        dispatch(showErrorAlert(res?.data?.message));
      }
    });
  };

  const navigateToAddPage = () => {
    history.push(`properties/add`);
  };

  const rowTriggerAction = (row: any) => {
    history.push(`properties/${row?._id}`);
  };

  const handlePageChange = (e: any, value: any) => {
    setQueryParams({ page: value });
  };

  const handleRowsChange = (e: any) => {
    setQueryParams({ page: 0, pageSize: parseInt(e.target.value) });
  };

  const handleRowSelectionChange = (data: any) => {
    setSelectedRows(data);
  };

  return (
    <div className="container-fluid p-0">
      <GenericHeader
        selectedRows={selectedRows}
        onSearch={handleSearch}
        addResourceHandler={navigateToAddPage}
        deleteResourceHandler={deleteProperty}
        model="property"
      />
      <div className="p-4">
        <h2 className="mb-4">Properties</h2>
        <GenericTable
          list={propertiesData?.data?.data ? propertiesData.data.data : []}
          totalCount={propertiesData?.data?.total}
          onSelectedChange={handleRowSelectionChange}
          selectedRows={selectedRows}
          page={Number(queryParams.page)}
          loading={propertiesData.loading}
          rowsPerPage={Number(queryParams.pageSize)}
          onRowsChange={handleRowsChange}
          onPageChange={handlePageChange}
          image={placeholderPic}
          tableConfig={propertiesTableConfig}
          model="property"
          passRow={rowTriggerAction}
        />
      </div>
    </div>
  );
};

export default Properties;
