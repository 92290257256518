import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import React, { FormEvent } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import GenericHeader from "../../../components/GenericHeader/GenericHeader";
import AddEditBlockForm from "components/PageComponents/Blocks/AddEditBlockForm";
import useStoreSessionSelector from "../../../hooks/useStoreSessionSelector";
import BlockService from "services/Entities/BlockService";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../store/actions/alerts";
import { usePermissionChecker } from "hooks/useRoles";
import { IBlock } from "interfaces/Blocks";

const AddEditBlock = () => {
  const [formState, setFormState] = React.useState("create");
  const [object, setObject] = React.useState<IBlock>({
    name: "",
    description: "",
    properties: [],
  });

  const [loading, setLoading] = React.useState(false);
  const blocksClient = new BlockService();
  const session = useStoreSessionSelector();
  const { id }: any = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const permissionChecker = usePermissionChecker();

  const deleteResourceHandler = () => {
    if (!permissionChecker("delete-block")) return;

    setLoading(true);

    if (!id) {
      dispatch(
        showErrorAlert({
          message: "There is no block to delete! You are in create-mode.",
        })
      );
    } else {
      const payload = {
        id: id,
        token: session.token,
      };
      blocksClient
        .deleteResource(payload)
        .then((res) => {
          if (res?.data?.success) {
            dispatch(showSuccessAlert("Block deleted"));
            history.push("/blocks");
          } else {
            dispatch(showErrorAlert(res?.data?.message));
          }
        })
        .finally(() => setLoading(false));
    }
  };

  const fetchSpecificResource = () => {
    setLoading(true);

    blocksClient
      .getSpecificResource(id, {})
      .then((res) => {
        setFormState("edit");
        setObject(res["data"]["data"][0]);
      })
      .finally(() => setLoading(false));
  };

  const submitResource = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (formState === "create") {
      if (!permissionChecker("add-block", true)) return;

      setLoading(true);

      const payload = {
        ...object,
      };

      blocksClient
        .postResource(payload)
        .then((res) => {
          if (res?.data?.success) {
            dispatch(showSuccessAlert("Submitted Successfully!"));
            window.location.href = "/blocks";
          } else {
            dispatch(showErrorAlert(res?.data));
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      if (!permissionChecker("edit-block", true)) return;

      setLoading(true);

      const payload = {
        id: id,
        ...object,
      };

      blocksClient
        .patchResource(payload)
        .then((res) => {
          if (res?.data?.success) {
            dispatch(showSuccessAlert("Block Updated"));
          } else {
            dispatch(showErrorAlert(res?.data?.message));
          }
          fetchSpecificResource();
        })
        .finally(() => setLoading(false));
    }
  };

  React.useEffect(() => {
    if (id) {
      fetchSpecificResource();
    }
  }, [id]);

  return (
    <>
      <GenericHeader
        deleteResourceHandler={
          formState === "edit" ? deleteResourceHandler : undefined
        }
        model="block"
      />
      <form onSubmit={submitResource}>
        <Box display="flex" flexDirection="column" mx={4} my={6} gap={2}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5" color="text.primary">
              {formState === "create" ? "Add" : "Edit"} Block
            </Typography>
            <Button
              disableElevation
              color="primary"
              variant="contained"
              sx={{
                minWidth: "100px",
                maxHeight: "50px",
                py: 1.5,
                px: { xs: 1, md: 2 },
              }}
              type="submit"
            >
              {loading ? (
                <CircularProgress style={{ color: "#fff" }} />
              ) : (
                <>{formState === "create" ? "Add" : "Update"} Block</>
              )}
            </Button>
          </Box>

          <AddEditBlockForm
            object={object}
            setObject={setObject}
            loading={loading}
          />
        </Box>
      </form>
    </>
  );
};

export default AddEditBlock;
