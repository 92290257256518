import React from "react";
// import logo from "../../../assets/img/logo.svg";

const LoginCoverBackground: React.FC = () => {
  return (
    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0 bg-custom-gradient position-relative">
      <div className="img-overlay overflow-hidden"></div>
      <div className="d-flex justify-content-center align-items-center h-100 w-100 overflow-hidden">
        <img
          className="text-center opacity-1"
          style={{ zIndex: 1, width: "30%" }}
          src={process.env.REACT_APP_LOGO ?? window["REACT_APP_LOGO"]}
        />
      </div>
    </div>
  );
};

export default LoginCoverBackground;
