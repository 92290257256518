import React from "react";
import "./PageHeader.scss";
import Box from "@mui/material/Box";

const PageHeader = ({ children }: any) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ p: 2, px: 4, zIndex: 11 }}
    >
      {children}
    </Box>
  );
};

export default PageHeader;
