import React, { useState, useEffect } from "react";
import Amenities from "../Amenities/Amenities";
import LocationMap from "components/LocationMap/LocationMap";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import InputAdornment from "@mui/material/InputAdornment";

import { MdMeetingRoom, MdBed, MdBathtub, MdPhone } from "react-icons/md";
import { BiSolidArea } from "react-icons/bi";

import { Property } from "../../../../interfaces/Property";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import ImageDropzone from "components/ImageDropzone/ImageDropzone";
import DisplayImages from "components/DisplayImages/DisplayImages";
import PropertyService from "services/Entities/PropertyService";

type CenterType = {
  lng: number;
  lat: number;
};

type PropertyFormProps = {
  property: Property;
  setProperty: React.Dispatch<React.SetStateAction<Property>>;
  featured: boolean;
  setFeatured: React.Dispatch<React.SetStateAction<boolean>>;
  amenities: string[];
  setAmenities: React.Dispatch<React.SetStateAction<string[]>>;
  center: CenterType;
  setCenter: React.Dispatch<React.SetStateAction<CenterType>>;
  loading: boolean;
};

const PropertyForm = ({
  property,
  setProperty,
  featured,
  setFeatured,
  amenities,
  setAmenities,
  center,
  setCenter,
  loading,
}: PropertyFormProps) => {
  const [availabilityStatusArr, setAvailabilityStatusArr] = useState<string[]>(
    []
  );
  const [buildingTypesArr, setBuildingTypesArr] = useState<string[]>([]);
  const [propertyTypes, setPropertyTypes] = useState<string[]>([]);
  const [propertyTypesLoading, setPropertyTypesLoading] =
    useState<boolean>(false);
  const [buildingTypesLoading, setBuildingTypesLoading] =
    useState<boolean>(false);
  const [availabilityStatusLoading, setAvailabilityStatusLoading] =
    useState<boolean>(false);

  const propertiesClient = new PropertyService();

  const getAvailabilityStatuses = () => {
    setAvailabilityStatusLoading(true);
    propertiesClient
      .getSpecificResource("availability_statuses", {})
      .then((response: any) => {
        setAvailabilityStatusArr(response.data.data);
      })
      .finally(() => {
        setAvailabilityStatusLoading(false);
      });
  };

  const getBuildingTypes = () => {
    setBuildingTypesLoading(true);

    propertiesClient
      .getSpecificResource("building_types", {})
      .then((response: any) => {
        setBuildingTypesArr(response.data.data);
      })
      .finally(() => {
        setBuildingTypesLoading(false);
      });
  };

  const getPropertyTypes = () => {
    setPropertyTypesLoading(true);

    propertiesClient
      .getSpecificResource("property_types", {})
      .then((response: any) => {
        setPropertyTypes(response.data.data);
      })
      .finally(() => {
        setPropertyTypesLoading(false);
      });
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    e.preventDefault();
    let tempProperty: Property = { ...property };
    const key: any = e?.target?.name;
    let value: any = e?.target?.value;

    if (e.target.type === "number") {
      value = parseFloat(value);
    }

    if (Object.keys(property).includes(key))
      tempProperty = { ...property, [key]: value };
    setProperty(tempProperty);
  };

  const handleChangeSelect = (event: any) => {
    let tempProperty: Property = { ...property };
    switch (event.target.name) {
      case "property_type":
        tempProperty.property_type = event.target.value;
        break;
      case "building_type":
        tempProperty.building_type = event.target.value;
        break;
      case "availability_status":
        tempProperty.availability_status = event.target.value;
        break;
    }
    setProperty(tempProperty);
  };

  const removeImage = (photos_type: string, imageIndex: number) => {
    let tempProperty: Property = { ...property };
    tempProperty[photos_type].splice(imageIndex, 1);

    setProperty(tempProperty);
  };

  useEffect(() => {
    getPropertyTypes();
    getBuildingTypes();
    getAvailabilityStatuses();
  }, []);

  return (
    <Box
      display="grid"
      gridTemplateColumns={{ xs: "1fr", md: "1fr 1fr" }}
      gridTemplateRows={{ xs: "1fr 1fr", md: "1fr" }}
      gap={4}
    >
      {loading && (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: "background.paper",
            opacity: 0.8,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1,
          }}
        >
          <LoadingIndicator />
        </Box>
      )}
      <Stack gap={3}>
        {/* About Property */}
        <Box
          display="flex"
          flexDirection="column"
          py={2}
          px={3}
          pb={3}
          gap={3}
          sx={{ backgroundColor: "background.paper" }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body1" color="text.secondary">
              About Property
            </Typography>
            <FormControlLabel
              sx={{
                width: "fit-content",
                "& .MuiFormControlLabel-label": {
                  fontSize: 14,
                  textTransform: "uppercase",
                  color: "text.primary",
                },
              }}
              control={
                <Switch
                  size="small"
                  checked={featured ? featured : false}
                  onChange={() => setFeatured(!featured)}
                  name="featured"
                />
              }
              label="Featured"
            />
          </Box>
          <Box display="flex" flexDirection="column" gap={3}>
            <TextField
              required
              variant="outlined"
              size="small"
              label="Title"
              className="formInput"
              name="title"
              value={property ? property.title : ""}
              onChange={(e) => handleChange(e)}
            />
            <TextField
              required
              multiline
              rows={4}
              variant="outlined"
              size="small"
              label="Description"
              sx={{ maxWidth: "100%" }}
              className="formInput"
              name="description"
              value={property ? property.description : ""}
              onChange={(e) => handleChange(e)}
            />
            <Stack direction="row" flexWrap="wrap" gap={3}>
              <TextField
                variant="outlined"
                size="small"
                label="Price"
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">€</InputAdornment>
                  ),
                }}
                sx={{ minWidth: "100px", maxWidth: "20%" }}
                className="formInput"
                name="price"
                value={property ? property.price : ""}
                onChange={(e) => handleChange(e)}
              />
              <TextField
                required
                select
                variant="outlined"
                size="small"
                label="Availability Status"
                InputLabelProps={{ shrink: true }}
                sx={{ minWidth: "150px", maxWidth: "100%" }}
                className="formInput"
                name="availability_status"
                value={property.availability_status ?? availabilityStatusArr[0]}
                onChange={(e) => handleChangeSelect(e)}
              >
                {availabilityStatusLoading ? (
                  <CircularProgress />
                ) : (
                  availabilityStatusArr.map(
                    (availbilityStatus: string, index: number) => (
                      <MenuItem key={index} value={availbilityStatus}>
                        {availbilityStatus}
                      </MenuItem>
                    )
                  )
                )}
              </TextField>
            </Stack>
          </Box>
        </Box>

        {/* Property Type */}
        <Box
          display="flex"
          flexDirection="column"
          py={2}
          px={3}
          pb={3}
          gap={3}
          sx={{ backgroundColor: "background.paper" }}
        >
          <Typography variant="body1" color="text.secondary">
            Property Type
          </Typography>
          <Box display="flex" gap={3} flexWrap="wrap">
            <TextField
              required
              select
              variant="outlined"
              size="small"
              label="Property Type"
              InputLabelProps={{ shrink: true }}
              sx={{ minWidth: "200px", maxWidth: "100%" }}
              className="formInput"
              name="property_type"
              value={property.property_type ?? propertyTypes[0]}
              onChange={(e) => handleChangeSelect(e)}
            >
              {propertyTypesLoading ? (
                <CircularProgress />
              ) : (
                propertyTypes.map((propertyType: string, index: number) => (
                  <MenuItem key={index} value={propertyType}>
                    {propertyType}
                  </MenuItem>
                ))
              )}
            </TextField>
            <TextField
              required
              select
              variant="outlined"
              size="small"
              label="Building Type"
              InputLabelProps={{ shrink: true }}
              sx={{ minWidth: "200px", maxWidth: "100%" }}
              className="formInput"
              name="building_type"
              value={property.building_type ?? buildingTypesArr[0]}
              onChange={(e) => handleChangeSelect(e)}
            >
              {buildingTypesLoading ? (
                <CircularProgress />
              ) : (
                buildingTypesArr.map((buildingType: string, index: number) => (
                  <MenuItem key={index} value={buildingType}>
                    {buildingType}
                  </MenuItem>
                ))
              )}
            </TextField>
          </Box>
        </Box>

        {/* Property Details */}
        <Box
          display="flex"
          flexDirection="column"
          py={2}
          px={3}
          pb={3}
          gap={3}
          sx={{ backgroundColor: "background.paper" }}
        >
          <Typography variant="body1" color="text.secondary">
            Property Details
          </Typography>
          <Box
            display="grid"
            gridTemplateColumns="repeat(auto-fill, minmax(200px, 1fr))"
            gap={3}
          >
            <TextField
              variant="outlined"
              size="small"
              label="Total Rooms"
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MdMeetingRoom
                      fontSize={22}
                      style={{ padding: 0, margin: 0 }}
                    />
                  </InputAdornment>
                ),
              }}
              sx={{ minWidth: "100%" }}
              className="formInput"
              name="room_count"
              value={property ? property.room_count : ""}
              onChange={(e) => handleChange(e)}
            />

            <TextField
              variant="outlined"
              size="small"
              label="Bedrooms"
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MdBed fontSize={22} style={{ padding: 0, margin: 0 }} />
                  </InputAdornment>
                ),
              }}
              sx={{ minWidth: "100%" }}
              className="formInput"
              name="bedroom_count"
              value={property ? property.bedroom_count : ""}
              onChange={(e) => handleChange(e)}
            />

            <TextField
              variant="outlined"
              size="small"
              label="Bathrooms"
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MdBathtub
                      fontSize={22}
                      style={{ padding: 0, margin: 0 }}
                    />
                  </InputAdornment>
                ),
              }}
              sx={{ minWidth: "100%" }}
              className="formInput"
              name="bathroom_count"
              value={property ? property.bathroom_count : ""}
              onChange={(e) => handleChange(e)}
            />

            <TextField
              variant="outlined"
              size="small"
              label="Plot Size"
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BiSolidArea
                      fontSize={22}
                      style={{ padding: 0, margin: 0 }}
                    />
                  </InputAdornment>
                ),
              }}
              sx={{ minWidth: "100%" }}
              className="formInput"
              name="plot_size"
              value={property ? property.plot_size : ""}
              onChange={(e) => handleChange(e)}
            />
          </Box>
        </Box>

        {/* Amenities */}
        <Box
          display="flex"
          flexDirection="column"
          py={2}
          px={3}
          pb={3}
          gap={3}
          sx={{ backgroundColor: "background.paper" }}
        >
          <Typography variant="body1" color="text.secondary">
            Amenities
          </Typography>
          <Amenities amenities={amenities} setAmenities={setAmenities} />
        </Box>

        {/* Contact Information */}
        <Box
          display="flex"
          flexDirection="column"
          py={2}
          px={3}
          pb={3}
          gap={3}
          sx={{ backgroundColor: "background.paper" }}
        >
          <Typography variant="body1" color="text.secondary">
            Contact Information
          </Typography>
          <Box display="flex" gap={2} flexWrap="wrap">
            <TextField
              required
              variant="outlined"
              size="small"
              label="Phone"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MdPhone fontSize={22} style={{ padding: 0, margin: 0 }} />
                  </InputAdornment>
                ),
              }}
              sx={{ minWidth: "100px", maxWidth: "100%" }}
              className="formInput"
              name="contact_info"
              value={property ? property.contact_info : ""}
              onChange={(e) => handleChange(e)}
            />
          </Box>
        </Box>
      </Stack>

      <Box display="flex" flexDirection="column" gap={3}>
        {/* Location */}
        <Box
          display="flex"
          flexDirection="column"
          py={2}
          px={3}
          pb={3}
          gap={3}
          sx={{ backgroundColor: "background.paper" }}
        >
          <Typography variant="body1" color="text.secondary">
            Location
          </Typography>
          <Box display="flex" gap={3} flexWrap="wrap">
            <TextField
              required
              variant="outlined"
              size="small"
              label="Area"
              sx={{ minWidth: "100%" }}
              className="formInput"
              name="area"
              value={property ? property.area : ""}
              onChange={(e) => handleChange(e)}
            />

            <Box sx={{ height: "300px", width: "100%" }}>
              <LocationMap center={center} setCenter={setCenter} />
            </Box>
          </Box>
        </Box>

        {/* Property Images */}
        <Box
          display="flex"
          flexDirection="column"
          py={2}
          px={3}
          pb={3}
          gap={3}
          sx={{ backgroundColor: "background.paper" }}
        >
          <Typography variant="body1" color="text.secondary">
            Property Images
          </Typography>
          <Box display="flex" flexDirection="column" gap={2}>
            <DisplayImages
              type="property_photos"
              imageLinks={property.property_photos}
              removeImage={removeImage}
            />

            <ImageDropzone
              multiple
              entity={"property_photos"}
              object={property}
              setObject={setProperty}
            />
          </Box>
        </Box>

        {/* 360 Images */}
        <Box
          display="flex"
          flexDirection="column"
          py={2}
          px={3}
          pb={3}
          gap={3}
          sx={{ backgroundColor: "background.paper" }}
        >
          <Typography variant="body1" color="text.secondary">
            360 Images
          </Typography>
          <Box display="flex" flexDirection="column" gap={2}>
            <DisplayImages
              type="three_sixty_photo"
              imageLinks={property.three_sixty_photo}
              removeImage={removeImage}
            />

            <ImageDropzone
              multiple
              entity={"three_sixty_photo"}
              object={property}
              setObject={setProperty}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PropertyForm;
