import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";

import { AxiosResponse } from "axios";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import useStoreSessionSelector from "../../../hooks/useStoreSessionSelector";
import useUserData from "../../../hooks/useUserData";
import AuthenticationService from "../../../services/Entities/AuthenticationService";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../store/actions/alerts";
import QRModal from "../../QRModal/QRModal";
import { getUser } from "store/actions/user";

const Enable2FA = () => {
  const userInfo = useUserData();
  const [enable2FA, setEnable2FA] = React.useState<boolean>(
    userInfo?.MFA_enabled!
  );
  const [loading, setLoading] = React.useState<boolean>(false);
  const [img, setImg] = React.useState<string>();
  const [open, setOpen] = React.useState<boolean>(false);
  const authService = new AuthenticationService();
  const session = useStoreSessionSelector();
  const dispatch = useDispatch();
  const history = useHistory();

  const handle2FA = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    e.target.checked === true ? setEnable2FA(true) : setEnable2FA(false);
    const payload = {
      token: session.token,
    };
    if (e.target.checked) {
      authService
        .postSpecificResource("2fa/enable", payload)
        .then((response: AxiosResponse) => {
          // @ts-ignore
          setImg(response.data.qrCode);
          setOpen(true);
        })
        .finally(() => setLoading(false));
    } else {
      authService
        .postSpecificResource("2fa/disable", payload)
        .then((response) => {
          dispatch(showSuccessAlert("Disabled 2FA"));
          dispatch(getUser());
        })
        .finally(() => setLoading(false));
    }
  };
  return (
    <React.Fragment>
      <QRModal
        open={open}
        setOpen={setOpen}
        history={history}
        validationMode={true}
        imgUrl={img}
      />
      <h3>Configure 2-Factor Authentication</h3>
      <div className="text-center">
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Checkbox onChange={(e) => handle2FA(e)} checked={enable2FA} />
            {enable2FA ? (
              <span style={{ fontSize: "20px" }}>Disable 2FA</span>
            ) : (
              <span style={{ fontSize: "20px" }}>Enable 2FA</span>
            )}
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default Enable2FA;
