import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import Compressor from "compressorjs";
import ImageService from "services/Entities/ImageService";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton/IconButton";
import CircularProgress from "@mui/material/CircularProgress";

import { FaXmark } from "react-icons/fa6";

import "./ImageDropzone.scss";

const ImageDropzone = (props) => {
  const [files, setFiles] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const imagesClient = new ImageService();

  const removeFile = (file: any) => {
    const newFiles = [...files];
    newFiles.splice(newFiles?.indexOf(file), 1);
    setFiles(newFiles);
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      setLoading(true);

      const formData = new FormData();

      await Promise.all(
        acceptedFiles.map(async (file) => {
          const compressedFile = await new Promise((resolve) => {
            new Compressor(file, {
              quality: 0.9,
              maxWidth: 720,
              success(result) {
                resolve(result);
              },
            });
          });

          formData.append("images", compressedFile as File);
        })
      );

      imagesClient
        .postSpecificResource("add", formData)
        .then((response) => {
          const tempObj = structuredClone(props.object);

          if (props.entity === "user") {
            tempObj.imageLink = response?.data?.result?.[0]?.location;
          }

          if (props.entity === "property_photos") {
            response?.data?.result?.map((imageObject) => {
              if (tempObj["property_photos"] === undefined) {
                tempObj["property_photos"] = [];
                tempObj["property_photos"].push(imageObject?.location);
              } else {
                tempObj["property_photos"].push(imageObject?.location);
              }
            });
          }

          if (props.entity === "three_sixty_photo") {
            response?.data?.result?.map((imageObject) => {
              if (tempObj["three_sixty_photo"] === undefined) {
                tempObj["three_sixty_photo"] = [];
                tempObj["three_sixty_photo"].push(imageObject?.location);
              } else {
                tempObj["three_sixty_photo"].push(imageObject?.location);
              }
            });
          }

          console.log(tempObj);

          props.setObject(tempObj);
        })
        .finally(() => setLoading(false));

      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, { preview: URL.createObjectURL(file) })
        )
      );
    },
    [props.object]
  );

  // const onDrop = useCallback(
  //   async (acceptedFiles) => {
  //     setLoading(true);

  //     new Compressor(acceptedFiles[0], {
  //       quality: 0.9,
  //       maxWidth: 720,
  //       success(result) {
  //         const formData = new FormData();
  //         formData.append("images", result);

  //         console.log(acceptedFiles);

  //         // imagesClient
  //         //   .postSpecificResource("add", formData)
  //         //   .then((response) => {
  //         //     const tempObj = structuredClone(props.object);

  //         //     if (props.entity === "user") {
  //         //       tempObj.imageLink = response?.data?.result?.[0]?.location;
  //         //     }

  //         //     if (props.entity === "property_photos") {
  //         //       response?.data?.result?.map((imageObject) => {
  //         //         if (tempObj["property_photos"] === undefined) {
  //         //           tempObj["property_photos"] = [];
  //         //           tempObj["property_photos"].push(imageObject?.location);
  //         //         } else {
  //         //           tempObj["property_photos"].push(imageObject?.location);
  //         //         }
  //         //       });
  //         //     }

  //         //     if (props.entity === "three_sixty_photo") {
  //         //       response?.data?.result?.map((imageObject) => {
  //         //         if (tempObj["three_sixty_photo"] === undefined) {
  //         //           tempObj["three_sixty_photo"] = [];
  //         //           tempObj["three_sixty_photo"].push(imageObject?.location);
  //         //         } else {
  //         //           tempObj["three_sixty_photo"].push(imageObject?.location);
  //         //         }
  //         //       });
  //         //     }

  //         //     console.log(tempObj);

  //         //     props.setObject(tempObj);
  //         //   })
  //         //   .finally(() => setLoading(false));
  //       },
  //     });

  //     setFiles(
  //       acceptedFiles.map((file) =>
  //         Object.assign(file, { preview: URL.createObjectURL })
  //       )
  //     );
  //   },
  //   [props.object]
  // );

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: onDrop,
    ...props,
  });

  const thumbs = files.map((file) => (
    <div className="thumb" key={file.name}>
      <div className="thumb-inner">
        <img
          src={file.preview}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
        <IconButton
          disableRipple
          color="primary"
          onClick={() => {
            removeFile(file);
          }}
        >
          <FaXmark fontSize={16} />
        </IconButton>
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <>
      <section className="image-dropzone-container">
        <div
          {...getRootProps({ className: "dropzone" })}
          style={{ padding: "40px 0" }}
        >
          <input {...getInputProps()} />
          {loading && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <CircularProgress size="2rem" />
              <p>Uploading...</p>
            </Box>
          )}
          {!loading && (
            <p>
              {props.multiple
                ? "Drag 'n' drop some images here, or click to select images"
                : "Drag 'n' drop an image here, or click to select the image"}
            </p>
          )}
        </div>
      </section>
      {/* <aside className="thumb-container">{thumbs}</aside> */}
    </>
  );
};

export default ImageDropzone;
