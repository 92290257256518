import React from "react";
import { useDispatch } from "react-redux";

import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import useStoreAlertsSelector from "../../../hooks/useStoreAlertsSelector";
import { hideSuccessAlert } from "../../../store/actions/alerts";

import "./SuccessAlert.scss";

const SuccessAlert = () => {
  const storeAlerts = useStoreAlertsSelector();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideSuccessAlert());
  };

  return (
    <Snackbar
      TransitionComponent={Slide}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      autoHideDuration={5000}
      className="successAlert"
      onClose={handleClose}
      open={storeAlerts?.success?.open}
    >
      <Alert onClose={handleClose} severity="success">
        <AlertTitle>Success</AlertTitle>
        {storeAlerts?.success?.message && storeAlerts?.success?.message}
      </Alert>
    </Snackbar>
  );
};

export default SuccessAlert;
