import { AxiosResponse } from "axios";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import LoginMobile from "../../components/PageComponents/Authentication/LoginMobile";
import LoginPC from "../../components/PageComponents/Authentication/LoginPC";
import QRModal from "../../components/QRModal/QRModal";
import useScreenSize from "../../hooks/useScreenSize";
import { loginUser, postActionLogin } from "../../store/actions/session";
import "./Login.scss";

const Login: React.FC = () => {
  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [imgUrl, setImgUrl] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState<boolean>(false);

  const device = useScreenSize();
  const dispatch = useDispatch();
  const history = useHistory();

  const getImgUrl = (img: string) => setImgUrl(img);
  const detectErr = (e: boolean) => (e ? setOpen(false) : setOpen(true));

  const login = () => {
    setLoading(true);
    const payload = {
      email: email,
      password: password,
    };
    dispatch(loginUser(payload, setLoading, history, getImgUrl, detectErr));
  };

  const handleRememberMe = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      localStorage.setItem("REFRESH_AUTH", JSON.stringify(true));
    } else {
      if (localStorage.getItem("REFRESH_AUTH")) {
        localStorage.removeItem("REFRESH_AUTH");
      }
    }
  };

  const validated = (e: boolean, responseData: AxiosResponse) => {
    if (e)
      // @ts-ignore
      dispatch(postActionLogin(responseData.data.token, history));
  };

  return (
    <>
      <QRModal
        open={open}
        setOpen={setOpen}
        history={history}
        email={email}
        validationMode={false}
        password={password}
        validated={validated}
      />
      {device !== "mobile" ? (
        <LoginPC
          setEmail={setEmail}
          setPassword={setPassword}
          login={login}
          loading={loading}
          handleRememberMe={handleRememberMe}
        />
      ) : (
        <LoginMobile
          setEmail={setEmail}
          setPassword={setPassword}
          login={login}
          loading={loading}
          handleRememberMe={handleRememberMe}
        />
      )}
    </>
  );
};

export default Login;
