import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import React from "react";
import PasswordFields from "./PasswordFields";
import UserRoles from "./UserRoles";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import { useRoles } from "hooks/useRoles";

const AddEditUserForm = ({
  object,
  setObject,
  isEmailValid,
  password,
  setPassword,
  tempPassword,
  setTempPassword,
  userRole,
  setUserRole,
  loading,
}: any) => {
  const [emailError, setEmailError] = React.useState<boolean>(false);

  const roles = useRoles();

  const handleChange = (input: any) => {
    let tempObj: any = object;
    tempObj[input.name] = input.value;
    setObject({ ...tempObj });
  };

  React.useEffect(() => {
    let tempObj = { ...object };
    if (isEmailValid(tempObj.email)) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  }, [isEmailValid]);

  React.useEffect(() => {
    let tempObj = { ...object };
    tempObj.role = userRole;
    setObject(tempObj);
  }, [userRole]);

  React.useEffect(() => {
    let tempObj = { ...object };
    if (password === tempPassword) {
      tempObj.password = password;
    }
    setObject(tempObj);
  }, [password]);

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      {loading && (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: "background.paper",
            opacity: 0.8,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1,
          }}
        >
          <LoadingIndicator />
        </Box>
      )}

      <Box display="flex" flexDirection={{ xs: "column", md: "row" }} gap={3}>
        <Box
          display="flex"
          flexDirection="column"
          py={4}
          px={3}
          pb={3}
          gap={3}
          sx={{
            width: { xs: "100%", md: "40%" },
            backgroundColor: "background.paper",
          }}
        >
          <Typography variant="body1" color="text.secondary">
            User Details
          </Typography>

          <TextField
            required
            variant="outlined"
            size="small"
            label="Full Name"
            sx={{ minWidth: "80%" }}
            className="formInput"
            name="name"
            value={object ? object.name : ""}
            onChange={(e) => handleChange(e.target)}
          />

          <TextField
            required
            type="email"
            variant="outlined"
            size="small"
            label="Email"
            sx={{
              minWidth: "80%",
              ".MuiFormHelperText-root": {
                backgroundColor: "background.paper",
                mx: 0,
              },
            }}
            className="formInput"
            name="email"
            error={object?.email !== "" && emailError}
            helperText={
              object?.email !== "" && emailError ? "Email is not correct" : ""
            }
            value={object ? object.email : ""}
            onChange={(e) => handleChange(e.target)}
          />

          <PasswordFields
            password={password}
            tempPassword={tempPassword}
            setPassword={setPassword}
            setTempPassword={setTempPassword}
          />

          <UserRoles
            object={object}
            userRole={userRole}
            setUserRole={setUserRole}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          py={4}
          px={3}
          pb={3}
          gap={3}
          sx={{
            width: { xs: "100%" },
            backgroundColor: "background.paper",
          }}
        >
          <Typography variant="body1" color="text.secondary">
            Permission Details
          </Typography>

          {object.role ? (
            <Typography variant="body2" display="inline-block">
              Selected user role{" "}
              <Typography sx={{ color: "primary.main" }} display="inline-block">
                {userRole.toUpperCase()}
              </Typography>{" "}
              can perform the following actions:
            </Typography>
          ) : (
            <Typography variant="body2">No Role Selected</Typography>
          )}

          <Typography
            variant="body2"
            display="flex"
            flexDirection="column"
            gap={1}
          >
            {roles
              .find((role) => role.value === object.role)
              ?.permissions.map((permission, index) => (
                <li key={index}>
                  {permission.replace(/-/g, " ").toUpperCase()}
                </li>
              ))}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default AddEditUserForm;
