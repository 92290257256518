import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { useState, useEffect, FormEvent } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import GenericHeader from "../../../components/GenericHeader/GenericHeader";
import PropertyForm from "../../../components/PageComponents/Property/PropertyForm/PropertyForm";
import { Property } from "../../../interfaces/Property";
import PropertyService from "../../../services/Entities/PropertyService";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../store/actions/alerts";

import "../../Users/AddEditUser/AddEditUser.scss";
import { CircularProgress } from "@mui/material";
import { usePermissionChecker } from "hooks/useRoles";

const AddEditPropertyView = () => {
  const [formState, setFormState] = useState("create");
  const [object, setObject] = useState<Property>({
    title: "",
    price: 0,
    property_type: "",
    building_type: "",
    availability_status: "",
    map_location: {
      coordinates: [0, 0],
      type: "Point",
    },
    amenities: [],
    room_count: 0,
    bedroom_count: 0,
    bathroom_count: 0,
    description: "",
    plot_size: 0,
    area: "",
    contact_info: "",
    featured_status: false,
    property_photos: [],
    three_sixty_photo: [],
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [featured, setFeatured] = useState<boolean>(false);
  const [amenities, setAmenities] = useState<string[]>([]);
  const [center, setCenter] = useState({
    lng: 14.505752061734832, // Avant Office lng
    lat: 35.909860577659245, // Avant Office lat
  });

  const permissionChecker = usePermissionChecker();

  const propertiesClient = new PropertyService();
  const { id }: any = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const deleteResourceHandler = () => {
    if (!permissionChecker("delete-property", true)) return;

    setLoading(true);

    if (!id) {
      dispatch(
        showErrorAlert({
          message: "There is no property to delete! You are in create-mode.",
        })
      );
    } else {
      const payload = {
        id: id,
      };
      propertiesClient
        .deleteResource(payload)
        .then((res) => {
          if (res?.data?.success) {
            dispatch(showSuccessAlert("Properties Deleted"));
            history.push("/properties");
          } else {
            dispatch(showErrorAlert(res?.data?.message));
          }
        })
        .finally(() => setLoading(false));
    }
  };

  const fetchSpecificResource = () => {
    setLoading(true);

    propertiesClient
      .getSpecificResource(id, {})
      .then((res) => {
        setFormState("edit");
        setObject(res["data"]["data"][0]);
        setAmenities(res["data"]["data"][0]["amenities"]);
        setFeatured(res["data"]["data"][0]["featured_status"]);
        setCenter({
          lng:
            res?.["data"]?.["data"]?.[0]?.["map_location"]?.[
              "coordinates"
            ]?.[0] ?? 0,
          lat:
            res?.["data"]?.["data"]?.[0]?.["map_location"]?.[
              "coordinates"
            ]?.[1] ?? 0,
        });
      })
      .finally(() => setLoading(false));
  };

  const submitResource = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    if (formState === "create") {
      if (!permissionChecker("add-property", true)) return;

      setLoading(true);

      const payload = object;

      propertiesClient
        .postResource(payload)
        .then((res) => {
          if (res?.data?.success) {
            dispatch(showSuccessAlert(res?.data?.message));
            window.location.href = "/properties";
          } else {
            dispatch(showErrorAlert(res?.data?.message));
          }
        })
        .finally(() => setLoading(false));
    } else {
      if (!permissionChecker("edit-property", true)) return;

      setLoading(true);

      const payload = { id: id, ...object };

      propertiesClient
        .patchResource(payload)
        .then((res) => {
          if (res?.data?.success) {
            dispatch(showSuccessAlert("Property Updated"));
          } else {
            dispatch(showErrorAlert(res?.data?.message));
          }
          fetchSpecificResource();
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (id) {
      fetchSpecificResource();
    }
  }, [id]);

  useEffect(() => {
    let tempObject = { ...object };
    tempObject.amenities = amenities;
    setObject(tempObject);
  }, [amenities]);

  useEffect(() => {
    let tempObject = { ...object };
    tempObject.featured_status = featured;
    setObject(tempObject);
  }, [featured]);

  useEffect(() => {
    let tempObj = { ...object };
    if (tempObj.map_location && tempObj.map_location.coordinates) {
      tempObj.map_location.coordinates = [center.lng, center.lat];
    } else {
      tempObj.map_location = {
        type: "Point",
        coordinates: [center.lng, center.lat],
      };
    }
    setObject(tempObj);
  }, [center]);

  return (
    <>
      <GenericHeader
        deleteResourceHandler={
          formState === "edit" ? deleteResourceHandler : undefined
        }
        model="property"
      />
      <form onSubmit={submitResource}>
        <Box display="flex" flexDirection="column" mx={4} my={6} gap={2}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5" color="text.primary">
              {formState === "create" ? "Add" : "Edit"} Property
            </Typography>
            <Button
              disableElevation
              color="primary"
              variant="contained"
              sx={{
                minWidth: "100px",
                maxHeight: "50px",
                py: 1.5,
                px: { xs: 1, md: 2 },
              }}
              type="submit"
            >
              {loading ? (
                <CircularProgress style={{ color: "#fff" }} />
              ) : (
                <>{formState === "create" ? "Add" : "Update"} Property</>
              )}
            </Button>
          </Box>
          <PropertyForm
            property={object}
            setProperty={setObject}
            featured={featured}
            setFeatured={setFeatured}
            amenities={amenities}
            setAmenities={setAmenities}
            center={center}
            setCenter={setCenter}
            loading={loading}
          />
        </Box>
      </form>
    </>
  );
};

export default AddEditPropertyView;
