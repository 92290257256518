import React from "react";
import { Redirect, Route } from "react-router-dom";

const SessionRoute = ({ component: Component, token, ...rest }: any) => (
  <Route
    {...rest}
    render={(props) => (token ? <Redirect to="/" /> : <Component {...props} />)}
  />
);

export default SessionRoute;
