import IconButton from "@mui/material/IconButton/IconButton";

import { FaXmark } from "react-icons/fa6";

import "./DisplayImages.scss";

interface DisplayImagesInterface {
  type: string;
  imageLinks: string[];
  removeImage: any;
}

const RenderImage = ({ index, type, imageLink, removeImage }: any) => {
  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <img src={imageLink} className="uploaded-image" />
      <IconButton
        disableRipple
        color="primary"
        onClick={() => {
          removeImage(type, index);
        }}
      >
        <FaXmark fontSize={16} />
      </IconButton>
    </div>
  );
};

const DisplayImages = ({
  type,
  imageLinks,
  removeImage,
}: DisplayImagesInterface) => {
  return (
    <div className="display-image-container">
      {imageLinks &&
        imageLinks.length > 0 &&
        imageLinks.map((imageLink: any, index: number) => {
          return (
            <RenderImage
              key={index}
              index={index}
              type={type}
              imageLink={imageLink}
              removeImage={removeImage}
            />
          );
        })}
    </div>
  );
};

export default DisplayImages;
