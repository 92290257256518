import { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";

import {
  FaHouse,
  FaUsers,
  FaGear,
  FaPowerOff,
  FaEnvelope,
  FaCube,
} from "react-icons/fa6";
import { MdDashboard } from "react-icons/md";

// import logo from "../../assets/img/logo.svg";
import userPlaceholderImage from "../../assets/user-placeholder.png";
import useUserData from "hooks/useUserData";
import { logout } from "../../store/actions/session";

import "./Sidebar.scss";
import { Badge, useMediaQuery, useTheme } from "@mui/material";
import GenericModal from "components/Modal/Modal";
import Enable2FA from "components/PageComponents/Authentication/Enable2FA";
import { IconType } from "react-icons";

type TRouteItem = {
  path: string,
  label: string,
  routeActiveHandler: string,
  icon: IconType,
  newBadge?: boolean
};

const Sidebar = () => {
  const dispatch = useDispatch();
  const user = useUserData();
  const [modal2fa, setModal2fa] = useState<boolean>(false);

  const theme = useTheme();
  const isBelowSm = useMediaQuery(theme.breakpoints.down("sm"));

  const collapsed = useSelector((state: any) => state.sidebar.collapsed);

  const [activeRoute, setActiveRoute] = useState<string>(
    window.location.pathname.split("/")[1]
  );

  const [routes, setRoutes] = useState<TRouteItem[]>([]);

  const regularRoutes: TRouteItem[] = [
    {
      path: "/dashboard",
      label: "Dashboard",
      routeActiveHandler: "dashboard",
      icon: MdDashboard,
      newBadge: true
    },
    {
      path: "/properties",
      label: "Properties",
      routeActiveHandler: "properties",
      icon: FaHouse,
    },
    {
      path: "/blocks",
      label: "Blocks",
      routeActiveHandler: "blocks",
      icon: FaCube,
      newBadge: true
    },
    // {
    //   path: "/settings",
    //   label: "Settings",
    //   routeActiveHandler: "settings",
    //   icon: FaGear,
    // },
  ];

  const adminRoutes: TRouteItem[] = [
    ...regularRoutes,
    {
      path: "/users",
      label: "Users",
      routeActiveHandler: "users",
      icon: FaUsers,
    },
    {
      path: "/mailing-list",
      label: "Mailing List",
      routeActiveHandler: "mailing-list",
      icon: FaEnvelope,
    },
  ];

  useEffect(() => {
    user?.role === "admin"
      ? setRoutes([...adminRoutes])
      : setRoutes([...regularRoutes]);
  }, [user]);

  const { pathname } = useLocation();

  const logUserOut = (): void => {
    dispatch(logout());
  };

  const close2faModal = (): void => {
    setModal2fa(false);
  };

  return (
    <Box
      className={"sidebar " + (collapsed ? "" : "full")}
      sx={{
        backgroundColor: isBelowSm ? "secondary.main" : "",
        marginLeft: isBelowSm ? (collapsed ? "-78px" : "") : "",
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={4}
        sx={{ width: "100%", flexGrow: 1 }}
      >
        <Box sx={{ width: collapsed ? "auto" : "100px", alignSelf: "center" }}>
          <img
            className="logo"
            // src={logo}
            src={process.env.REACT_APP_LOGO ?? window["REACT_APP_LOGO"]}
            style={{ maxWidth: collapsed ? "100px" : "150px" }}
          />
        </Box>

        <Box
          className="linksBox"
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems={collapsed ? "center" : ""}
          gap={collapsed ? 2 : 0}
        >
          {routes.map((route: any, index: number) => (
            <Fragment key={route.path}>
              <Link
                to={{
                  pathname: route.path,
                  state: pathname,
                }}
                className={
                  "sideLink " +
                  (activeRoute === route.routeActiveHandler ? "active" : "")
                }
                style={
                  collapsed
                    ? {
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }
                    : {}
                }
                onClick={() => setActiveRoute(route.routeActiveHandler)}
              >
                {route.icon ? <route.icon fontSize={22} /> : route.imgSrc}
                {!collapsed && !isBelowSm && (
                  <span style={{ marginLeft: "35px" }}>{route.label}</span>
                )}
                {route.newBadge ? <Badge
                  style={{ marginLeft: "25px", marginBottom: "15px" }}
                  badgeContent={"New!"}
                  color="primary"></Badge> : <></>}
              </Link>
            </Fragment>
          ))}
          <div
            onClick={() => setModal2fa(true)}
            className="sideLink"
            style={
              collapsed
                ? {
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }
                : {}
            }
          >
            <FaGear />
            {!collapsed && !isBelowSm && (
              <span style={{ marginLeft: "35px" }}>Settings</span>
            )}
          </div>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              alignSelf: "center",
              mt: collapsed ? 0 : 2,
            }}
          >
            <Avatar alt="User Avatar" src={userPlaceholderImage} />
            {!collapsed && (
              <p className="text-center roleDisplay">
                {user?.role === "admin" ? "Admin" : "Employee"}
              </p>
            )}
          </Box>
        </Box>

        <GenericModal size="small" state={modal2fa} onClose={close2faModal}>
          <Enable2FA />
          <div className="d-flex justify-content-center mt-3">
            <button className="btn btn-secondary" onClick={close2faModal}>
              Close
            </button>
          </div>
        </GenericModal>
      </Box>

      <IconButton
        onClick={logUserOut}
        sx={{ border: "1px solid #FF1C1C", my: 6 }}
      >
        <FaPowerOff color="#FF1C1C" fontSize={"1rem"} />
      </IconButton>
    </Box>
  );
};

export default Sidebar;
