import { useState, useEffect } from "react";

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import GenericTable from "components/GenericTable/GenericTable";
import GenericModal from "components/Modal/Modal";
import { Property } from "interfaces/Property";

import { useApiHook } from "providers/ApiProvider";

import placeholderPic from "../../../assets/no_data_x.gif";
import propertiesTableConfig from "configs/propertiesTable";
import SearchIcon from "@mui/icons-material/Search";
import { useDebounce } from "use-debounce";
import { IBlock } from "interfaces/Blocks";

interface AddPropertyInBlockModalProps {
  addPropertyDialog: boolean;
  setAddPropertyDialog: React.Dispatch<React.SetStateAction<boolean>>;
  object: IBlock;
  setObject: React.Dispatch<React.SetStateAction<IBlock>>;
}

const AddPropertyInBlockModal = ({
  addPropertyDialog,
  setAddPropertyDialog,
  object,
  setObject,
}: AddPropertyInBlockModalProps) => {
  const [selectedRows, setSelectedRows] = useState([]);

  const [q, setQ] = useState("");
  const [debouncedSearchValue] = useDebounce(q, 300);

  useEffect(() => {
    setQ(debouncedSearchValue);
  }, [debouncedSearchValue]);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const query = {
    title: debouncedSearchValue,
    page: page,
    pageSize: pageSize,
  };

  const propertiesData = useApiHook("Property", "getAllResources", { query });

  const handlePageChange = (value: any) => {
    setPage(Number(value));
  };

  const handleRowsChange = (e: any) => {
    setPage(0);
    setPageSize(parseInt(e.target.value));
  };

  const handleRowSelectionChange = (data: any) => {
    setSelectedRows(data);
  };

  const handleAddProperties = () => {
    setObject({
      ...object,
      properties: [...object.properties, ...selectedRows],
    });

    setAddPropertyDialog(false);
  };

  return (
    <GenericModal
      state={addPropertyDialog}
      onClose={() => setAddPropertyDialog(false)}
      size="large"
      backgroundColor="#F6F7FC"
    >
      <Box display="flex" flexDirection="column" gap={2} p={2}>
        <TextField
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "primary.main",
                    p: 0.5,
                    borderRadius: "2px",
                    mr: -1,
                  }}
                >
                  <SearchIcon
                    fontSize="medium"
                    sx={{
                      color: "white.main",
                    }}
                  />
                </Box>
              </InputAdornment>
            ),
          }}
          placeholder="Search..."
          variant="outlined"
          value={q}
          onChange={(e) => setQ(e.target.value)}
          sx={{
            width: "300px",
            alignSelf: "end",
            boxShadow: "0px 6px 13px 0px #00000005",
            backgroundColor: "background.paper",
            "& fieldset": { border: "none" },
          }}
        />

        <Button
          disableElevation
          disabled={selectedRows.length === 0}
          color="primary"
          variant="contained"
          onClick={handleAddProperties}
          sx={{ alignSelf: "center" }}
        >
          Add {selectedRows.length} Properties
        </Button>

        <GenericTable
          hideActionButton
          selectFullData
          list={propertiesData?.data?.data ? propertiesData.data.data : []}
          disabledRows={object.properties.map(
            (property: Property) => property._id ?? ""
          )}
          totalCount={propertiesData?.data?.total}
          onSelectedChange={handleRowSelectionChange}
          selectedRows={selectedRows}
          page={Number(page)}
          loading={propertiesData.loading}
          rowsPerPage={Number(pageSize)}
          onRowsChange={handleRowsChange}
          onPageChange={handlePageChange}
          image={placeholderPic}
          tableConfig={propertiesTableConfig}
          model="property"
          passRow={() => {}}
        />
      </Box>
    </GenericModal>
  );
};

export default AddPropertyInBlockModal;
