import { useMemo, useState } from "react";
import dayjs from "dayjs";

import { useActiveUserPermissions } from "hooks/useRoles";
import NoResourceAvailable from "../NoResourceAvailable/NoResourceAvailable";
import TableSortLabel from "@mui/material/TableSortLabel";
import ColumnOptions from "./ColumnOptions";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";

import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow, { TableRowProps } from "@mui/material/TableRow";
import Button from "@mui/material/Button";

import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  FaFile,
  FaFileImage,
  FaFilePdf,
  FaCheck,
  FaXmark,
} from "react-icons/fa6";

import "./GenericTable.scss";

export interface GenericTableParams<T = any> {
  list: T[];
  totalCount: number;
  selectedRows: any;
  page: number;
  onSelectedChange: any;
  rowsPerPage: number;
  onRowsChange: any;
  onPageChange: any;
  model: string;
  passRow: any;
  image: string;
  tableConfig: any;
  loading: boolean;
  hideActionButton?: boolean;
  showCheckbox?: boolean;
  frontendPagination?: boolean;
  getRowProps?: (row: T, index: number) => TableRowProps;
  columnSort?: any;
  columnSortDir?: "asc" | "desc";
  onColumnSort?: (c: any) => any;
  selectFullData?: boolean;
  disabledRows?: string[];
  showViewButton?: boolean;
  onViewClick?: (row: T) => void;
}

const GenericTable = <T = any,>(params: GenericTableParams<T>) => {
  const [selected, setSelected] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const currentUserPermissions = useActiveUserPermissions();

  const storedTableConfig = localStorage.getItem(params.model + "TableConfig");
  const initialTableConfig = storedTableConfig
    ? JSON.parse(storedTableConfig)
    : params.tableConfig || [];
  const [tableConfig, setTableConfig] = useState(initialTableConfig);

  const navigate = (row: any) => {
    params.passRow(row);
  };

  const renderFiles = (row: any, object: any): any => {
    let data: any = row[object.attribute];
    let max_files: number = 2;

    if (Array.isArray(data)) {
      return (
        <div>
          {data.length == 0
            ? "none"
            : data.map((file, index) =>
              index >= max_files ? (
                ""
              ) : (
                <a
                  rel="noreferrer"
                  target="_blank"
                  key={file.url}
                  href={file.url}
                >
                  {file.type === "image" ? (
                    <FaFileImage className="fontAwesomeIcon" />
                  ) : (
                    <FaFile className="fontAwesomeIcon" />
                  )}
                </a>
              )
            )}
          <>{data.length > max_files ? "..." : ""}</>
        </div>
      );
    } else {
      return "not a files array...";
    }
  };

  const handleSelectionChange = (row: any) => {
    let data = [...params.selectedRows];
    const rowId = params.selectFullData ? row : row._id;
    const includesRow = params.selectFullData
      ? data.some((item) => item._id === row._id)
      : data.includes(rowId);

    if (includesRow) {
      data = params.selectFullData
        ? data.filter((item) => item._id !== row._id)
        : data.filter((item) => item !== rowId);
    } else {
      data.push(rowId);
    }
    setSelected(data);
    params.onSelectedChange(data);
  };

  const selectAll = (checked: any) => {
    let dataList = [...params.selectedRows];
    if (checked) {
      params.list.forEach((item: any) => {
        const includesItem = params.selectFullData
          ? dataList.some((dataItem) => dataItem._id === item._id)
          : dataList.includes(item._id);

        if (
          !includesItem &&
          !(params.disabledRows
            ? params.disabledRows.includes(item._id)
            : false)
        ) {
          dataList.push(params.selectFullData ? item : item._id);
        }
      });
      dataList = [...new Set(dataList)];
      setSelected(dataList);
    } else {
      dataList = params.selectFullData
        ? []
        : dataList.filter(
          (listItem) => !params.list.some((item) => listItem === item["_id"])
        );
      setSelected(dataList);
    }
    params.onSelectedChange(dataList);
  };

  const isSelectedAll = useMemo(() => {
    if (params.selectedRows?.length === 0) return false;

    return params.list?.every(
      (item) =>
        (params.disabledRows
          ? params.disabledRows.includes(item["_id"])
          : false) ||
        (params.selectFullData
          ? params.selectedRows.some(
            (selectedItem) => selectedItem._id === item["_id"]
          )
          : params.selectedRows.includes(item["_id"]))
    );
  }, [params.selectedRows, params.list, params.disabledRows]);

  const list = useMemo(() => {
    if (params.frontendPagination) {
      return params.list?.slice(
        params.page * params.rowsPerPage,
        params.page * params.rowsPerPage + params.rowsPerPage
      );
    } else {
      return params.list || [];
    }
  }, [params.frontendPagination, params.list, params.page, params.rowsPerPage]);

  if (loading || params.loading) {
    return <LoadingIndicator />;
  }

  const handleViewClick = (row: any) => {
    if (params.onViewClick) {
      params.onViewClick(row);
    }
  };

  return (
    <Box
      alignItems="flex-start"
      className="usersList w-100"
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <>
        <TableContainer component={Paper} className="tableContainer">
          <Table className="usersListTable">
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  {params.showCheckbox !== false && (
                    <Checkbox
                      className="checkBox"
                      onChange={(e) => selectAll(e.target.checked)}
                      checked={isSelectedAll}
                    />
                  )}
                </TableCell>
                {tableConfig.map((row: any, index: number) => {
                  if (row.selected) {
                    return (
                      <TableCell
                        key={row.attribute || index}
                        align="left"
                        sortDirection={
                          row.attribute === params.columnSort
                            ? params.columnSortDir
                            : false
                        }
                      >
                        {row.sortable && params.columnSort ? (
                          <>
                            <TableSortLabel
                              className="sortLabel"
                              active={row.attribute === params.columnSort}
                              direction={
                                row.attribute === params.columnSort
                                  ? params.columnSortDir
                                  : "asc"
                              }
                              onClick={() =>
                                params.onColumnSort?.(row.attribute)
                              }
                            >
                              {row.heading}
                            </TableSortLabel>
                          </>
                        ) : (
                          <>{row.heading}</>
                        )}
                      </TableCell>
                    );
                  }
                })}
                <TableCell align="right">
                  <ColumnOptions
                    tableConfig={tableConfig}
                    setTableConfig={setTableConfig}
                    model={params.model}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            {params.totalCount > 0 && !params.loading && (
              <TableBody>
                {list.map((row: any, index: number) => (
                  <TableRow
                    className={
                      params.selectFullData
                        ? params.selectedRows.some(
                          (item) => item._id === row._id
                        )
                        : params.selectedRows.includes(row._id)
                          ? "activeRow"
                          : ""
                    }
                    key={row._id || index}
                    {...params.getRowProps?.(row, index)}
                  >
                    <TableCell align="left">
                      {params.showCheckbox !== false && (
                        <Checkbox
                          className="checkBox"
                          color="primary"
                          value={row._id ? row._id : ""}
                          checked={
                            (params.selectFullData
                              ? params.selectedRows.some(
                                (item) => item._id === row._id
                              )
                              : params.selectedRows.includes(row._id)) ||
                            (params.disabledRows
                              ? params.disabledRows.includes(row._id)
                              : false)
                          }
                          onChange={(e) => handleSelectionChange(row)}
                          onClick={(e) => e.stopPropagation()}
                          inputProps={{
                            "aria-label": "primary checkbox",
                          }}
                          disabled={
                            params.disabledRows
                              ? params.disabledRows.includes(row._id)
                              : false
                          }
                        />
                      )}
                    </TableCell>
                    {tableConfig.map((object: any, index: number) => {
                      if (object.selected) {
                        return (
                          <TableCell
                            key={(row._id || index) + object.attribute}
                            className="pointer"
                            align="left"
                          >
                            {/* if column is date, display formatted date */}
                            {object.type === "date" &&
                              (dayjs(row[object.attribute]).isValid() &&
                                dayjs(row[object.attribute]).isAfter(0)
                                ? dayjs(row[object.attribute]).format(
                                  "MM/DD/YYYY"
                                )
                                : "")}
                            {object.type === "datetime" &&
                              (dayjs(row[object.attribute]).isValid() &&
                                dayjs(row[object.attribute]).isAfter(0)
                                ? dayjs(row[object.attribute]).format(
                                  "MM/DD/YYYY hh:mm a"
                                )
                                : "--")}
                            {/*  */}
                            {object.type === "boolean" &&
                              (row[object.attribute] == true ? (
                                <FaCheck />
                              ) : (
                                <FaXmark />
                              ))}
                            {(object.type === "string" ||
                              object.type === "number") &&
                              (row[object.attribute]
                                ? row[object.attribute]
                                : "")}
                            {object.type == "currency" &&
                              (row[object.attribute]
                                ? "€ " + row[object.attribute]
                                : "")}
                            {object.type == "link" &&
                              (row[object.attribute] ? (
                                <a
                                  href={row[object.attribute]}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <FaFilePdf className="fontAwesomeIcon" />
                                </a>
                              ) : (
                                ""
                              ))}
                            {object.type === "avatar" &&
                              (row[object.attribute] ? (
                                <img
                                  src={
                                    (process.env.REACT_APP_CDN_URL ??
                                      window["REACT_APP_CDN_URL"]) +
                                    "/" +
                                    row[object.attribute]
                                  }
                                  style={{
                                    width: "50px",
                                    height: "auto",
                                  }}
                                />
                              ) : (
                                <img
                                  src="/avatar-placeholder.svg"
                                  style={{
                                    width: "50px",
                                    height: "auto",
                                  }}
                                />
                              ))}
                            {object.type == "files_array" &&
                              renderFiles(row, object)}
                            {object.type === "array" &&
                              (row[object.attribute]
                                ? row[object.attribute].length
                                : "")}
                          </TableCell>
                        );
                      }
                    })}
                    {!params.hideActionButton ? (
                      <TableCell align="right">
                        <Button
                          disableElevation
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            navigate(row);
                          }}
                          endIcon={
                            currentUserPermissions?.includes(
                              `edit-${params.model}`
                            ) ? (
                              <EditIcon />
                            ) : (
                              <VisibilityIcon />
                            )
                          }
                        >
                          {currentUserPermissions?.includes(
                            `edit-${params.model}`
                          )
                            ? "Edit"
                            : "View"}
                        </Button>
                        {params.showViewButton && (
                          <Button
                            disableElevation
                            color="primary"
                            variant="contained"
                            onClick={() => handleViewClick(row)}
                            endIcon={<VisibilityIcon />}
                            style={{ marginLeft: 8 }}
                          >
                            Properties
                          </Button>
                        )}
                      </TableCell>
                    ) : (
                      <TableCell></TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
          {params.totalCount === 0 && (
            <NoResourceAvailable
              resource={params.model + "s"}
              image={params.image}
              addButtonLine={true}
            />
          )}
        </TableContainer>
        {params.totalCount > 0 && (
          <>
            <TablePagination
              className="pagination"
              component="div"
              page={params.page}
              rowsPerPage={params.rowsPerPage}
              rowsPerPageOptions={[5, 10, 20, 30]}
              count={params.totalCount}
              onPageChange={params.onPageChange}
              onRowsPerPageChange={params.onRowsChange}
            />
          </>
        )}
      </>
    </Box>
  );
};
export default GenericTable;
